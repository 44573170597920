import React from "react";
import { Button } from "react-bootstrap";
import Tag from "../../../Components/Tags/Tag";
import Title from "../../../Components/Title/Title";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import token from "../Assets/token_sus.png";
import { ReactComponent as Heart } from "../Assets/heart.svg";
import { ReactComponent as Built } from "../Assets/built.svg";
import { ReactComponent as Smile } from "../Assets/smile.svg";
import { ReactComponent as Market } from "../Assets/market.svg";

import "./Mechanics.scss";
import MobileCarousel from "./MobileCarousel";

export default function Mechanics() {
  return (
    <div className="large-container ">
      <div id="mechanics-section" className=" my-5 pb-5">
        <div className=" mb-4 d-lg-block d-none d-flex justify-content-center align-items-center">
          <div className="row">
            <div className="col-lg-3 col-12 px-2">
              <div className="full-height-col rounded-24">
                <div className="left-col card-project rounded-24     ">
                  <img src={token} className="img-fluid" />
                </div>

                <div className="mb-3">
                  <div className="left-col card-project px-md-4 px-2 rounded-24    ">
                    <div className="d-flex">
                      {" "}
                      <Tag
                        background="linear-gradient(45deg, #FF9C42, #C236FF)"
                        color="#000"
                        uppertitle="$LIPO"
                      />
                    </div>
                    <div className="font-size-48 lipo-gradient font-iner-bold color-gradient-lipo line-height-1-1">
                      Mystery Games
                    </div>
                    <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-2">
                      Solve puzzles, uncover secrets, and investigate hidden
                      clues. Take on the role of detective, investigator, or
                      someone trying to solve a mysterious event, crime, or
                      situation.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12 px-2">
              <div className="mb-3">
                <div className="left-col card-project p-xl-4 p-md-3 px-2 rounded-24 d-flex    ">
                  <div className="d-flex">
                    <div className="mt-auto">
                      {" "}
                      <div className="d-flex">
                        {" "}
                        <Tag
                          background="linear-gradient(45deg, #E0FF36, #FF9C42)"
                          color="#000"
                          uppertitle="UNIQUE"
                        />
                      </div>
                      <Title title="Arcade Games" size={32} />
                      <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-2">
                        Enjoy digital versions of classic arcade games that are
                        easy to pick up and play, while offering challenging
                        levels or high scores to beat.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="left-col card-project p-xl-4 p-md-3 px-2 rounded-24 d-flex    ">
                  <div className="mt-auto">
                    <Heart className="mb-3" />
                    <Title title="Treasure Hunt" size={32} />
                    <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-2">
                      Search for hidden treasures, solve puzzles, and uncover
                      secrets to enjoy the excitement, sense of discovery, and
                      the thrill of chasing down hidden rewards.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12 px-2">
              <div className="mb-3">
                <div className="left-col card-project p-xl-4 p-md-3 px-2 rounded-24 d-flex    ">
                  <div className="mt-auto">
                    <Smile className="mb-3" />
                    <Title title="Collectibles" size={32} />
                    <div className="font-size-18 gray-color font-iner-regulat mt-2 line-height-1-2">
                      Find, acquire, or collect items as you progress through
                      games to enhance your experience and showcase your talents
                      and abilities.
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="left-col card-project p-xl-4 p-md-3 px-2 rounded-24 d-flex   bg-gradient-to-r from-blue-500 to-purple-500  background-lipo-gradient">
                  <div className="d-flex">
                    <div className="mt-auto">
                      {" "}
                      <div className="d-flex">
                        {" "}
                        <Tag
                          background="linear-gradient(45deg, #E0FF36, #FF9C42)"
                          color="#000"
                          uppertitle="UNIQUE CHANCE"
                        />
                      </div>
                      <Title title="Start NOW" size={32} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12 px-2">
              {" "}
              <div className="mb-3">
                <div className="left-col card-project p-xl-4 p-md-3 px-2 rounded-24 d-flex    ">
                  <div className="mt-auto">
                    <Market className="mb-3" />

                    <Title title="Skill Games" size={32} />
                    <div className="font-size-18 gray-color mt-2 font-iner-regulat line-height-1-2">
                      Use your abilities, strategies, and decision-making to
                      succeed, rather than relying purely on luck or chance.
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="left-col card-project p-xl-4 p-md-3 px-2 rounded-24 d-flex    ">
                  <div className="mt-auto">
                    <Built className="mb-3" />
                    <Title title="Predict the Future" size={32} />
                    <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-2">
                      Predict upcoming events or outcomes based on certain
                      inputs, data, or simulations by combining elements of
                      strategy, knowledge, and chance, with the goal of making
                      correct predictions to earn rewards or points.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4 d-lg-none d-block">
          <MobileCarousel />
        </div>
      </div>
    </div>
  );
}
