import React from "react";
import "./HomepageHero.scss";
import { Row } from "react-bootstrap";
import Tag from "../../../Components/Tags/Tag";
import Button from "../../../Components/Button/Button";
import { useNavigate } from "react-router-dom";

const JoinZixxar = () => {
  return (
    <section id="homepage-hero" className="pt-5">
      <div className="section-padding white-color h-100">
        <div className="large-container mb-4 h-100 ">
          <Row className="h-100">
            <div className="col-lg-7 col-md-7 col-12 order-md-1 order-2 d-flex justify-content-center align-items-center mb-xl-5 mb-5">
              <div className="d-flex flex-column mb-md-0 justify-content-center align-items-center mt-5 pt-5 pt-md-0 justify-content-md-start align-items-md-start develop-container">
                <Tag
                  background="linear-gradient(45deg, #FF9C42, #C236FF)"
                  color="#000"
                  uppertitle="Gaming Platform"
                />
                <h2 className="font-size-80 font-iner-bold line-height-1 mb-0 li text-md-start text-center">
                  Decentralized<br></br>
                  Gaming Platform.
                </h2>

                <div className="d-flex">
                  <Button
                    title="Play Now"
                    height={48}
                    width={150}
                    heightResponsive={48}
                    heightResponsive580={40}
                    minWidth580={150}
                    color="#fff"
                    outlineColor="transparent"
                    bgColor="linear-gradient(90deg, #8F2EB7 0%,  #B97338 100%) 0% 0% no-repeat padding-box "
                    hoverColor="#fff"
                    bgHoverColor="#08FF9B"
                    customClass="z-2 radius64 font-ri-m font-size-20 me-3  mt-4"
                    callback={(e) => {
                      window.open("https://play.livepot.com/", "_blank");
                    }}
                  />
                </div>

                {/* <div className="d-flex btn-container">
                  <button className="join-btn">
                    <div className="inner-container font-size-24 font-pnm" onClick={comingSoonHandler}>
                      Join as member
                    </div>
                  </button>
                  <button className="enroll-btn">
                    <div className="inner-container font-size-24 font-pnm" onClick={comingSoonHandler}>
                      Enroll your project
                    </div>
                  </button>
                </div> */}
              </div>
            </div>

            <div className="col-md-6 col-12 order-md-2 order-1 pb-lg-0 pb-5 position-relative  ">
              <div className="">
                {/* <img className="img-lg-fluid" src={HeroLeft} alt="" /> */}
              </div>
            </div>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default JoinZixxar;
