import React from "react";
import "./PlatformForComunity.scss";
import { FiPlusCircle } from "react-icons/fi";
import img from "./assets/img.png";
import img1 from "./assets/img1.png";
export default function PlatformForComunity() {
  return (
    <div
      id="platform-for-comunity"
      className="font-iner-bold  white-color line-height-1 my-5"
    >
      <div className="large-container ">
        <h2 className="mb-5 font-size-64 mb-4">
          A platform built <br></br>{" "}
          <span className="font-size-64 color-gradient-lipo mb-4">
            for Community.
          </span>
        </h2>
        <br></br>
        <div className="row mt-4">
          <div className="col-md-6 mb-3">
            <div className="card-1 card d-flex justify-content-center">
              <div className="card-body my-auto d-flex flex-column justify-content-center">
                <h5 className="card-title  font-size-32  font-iner-bold">
                  Play with $LIPO
                </h5>
                <p className="card-text  font-size-16 font-iner-regular mw-300">
                  Put your $LIPO to use and play whatever game you want.
                </p>
                <div className="">
                  <button
                    onClick={() => window.open("https:/play.livepot.com")}
                    className="btn-outline-btn px-4 py-2 d-flex align-items-center justify-content-between font-iner-regular"
                    style={{
                      transition: "transform 0.2s",
                      transform: "translateY(0)",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "translateY(-5px)")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "translateY(0)")
                    }
                  >
                    <div>Play</div> <FiPlusCircle className="ms-2" />
                  </button>
                </div>
                <img src={img} alt="nft" className="abs-image" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-2 card">
              <div className="card-body my-auto d-flex flex-column justify-content-center">
                <h5 className="card-title  font-size-32 font-iner-bold">
                  Play with NFT
                </h5>
                <p className="card-text font-size-16 font-iner-regular mw-300">
                  You can also play games using your special NFTs. Get started
                  now!
                </p>
                <div className="">
                  <button
                    onClick={() => window.open("https:/play.livepot.com")}
                    className="btn-outline-btn px-4 py-2 d-flex align-items-center justify-content-between font-iner-regular"
                    style={{
                      transition: "transform 0.2s",
                      transform: "translateY(0)",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "translateY(-5px)")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "translateY(0)")
                    }
                  >
                    <div>Play</div> <FiPlusCircle className="ms-2" />
                  </button>
                </div>
                <img src={img1} alt="nft" className="abs-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
