import React, { useRef, useState } from "react";
import Title from "../../../Components/Title/Title";
import SliderRight from "../../../Components/SliderRight/SliderRight";
import Tag from "../../../Components/Tags/Tag";
import heart from "..//Assets/heart.png";
import smile from "..//Assets/smile.png";
import market from "..//Assets/market.png";
import built from "..//Assets/built.png";
import token from "../Assets/token_sus.png";
import { FiPlusCircle } from "react-icons/fi";

const MobileCarousel = () => {
  const sliderRef = useRef(null);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);

  return (
    <section
      id="mechanics-carousel"
      className="section-interspace large-container  px-0"
    >
      <SliderRight
        sliderRef={sliderRef}
        atStart={atStart}
        atEnd={atEnd}
        setAtEnd={setAtEnd}
        setAtStart={setAtStart}
        extraClass="slider-right-tokenomics"
      >
        <div className="left-col card-project p-xl-4 p-md-3 px-4 rounded-24 d-flex    py-4">
          <div className="d-flex">
            <div className="first-child d-flex">
              <div className="mt-auto">
                {" "}
                <div className="d-flex w-auto">
                  {" "}
                  <Tag
                    background="linear-gradient(45deg, #FF9C42, #C236FF)"
                    color="#000"
                    uppertitle="$LIPO"
                  />
                </div>
                <div className="font-size-48 lipo-gradient font-iner-bold color-gradient-lipo line-height-1-1">
                  {" "}
                  Mystery Games
                </div>{" "}
                <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-2">
                  Solve puzzles, uncover secrets, and investigate hidden clues.
                  Take on the role of detective, investigator, or someone trying
                  to solve a mysterious event, crime, or situation.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="left-col card-project p-xl-4 p-md-3 px-4 rounded-24 d-flex    py-4">
          <div className="d-flex">
            <div className="first-child d-flex">
              <div className="mt-auto">
                {" "}
                <div className="d-flex w-auto">
                  {" "}
                  <Tag
                    background="linear-gradient(45deg, #E0FF36, #FF9C42)"
                    color="#000"
                    uppertitle="UNIQUE"
                  />
                </div>
                <Title title="Arcade Games" size={32} />
                <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-2">
                  Enjoy digital versions of classic arcade games that are easy
                  to pick up and play, while offering challenging levels or high
                  scores to beat.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="left-col card-project p-xl-4 p-md-3 px-4 rounded-24 d-flex    py-4">
          <div className="first-child d-flex">
            <div className="mt-auto">
              <img src={heart} className="mb-3 img-fluid" alt="" />
              <Title title="Treasure Hunt" size={32} />
              <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-3">
                Search for hidden treasures, solve puzzles, and uncover secrets
                to enjoy the excitement, sense of discovery, and the thrill of
                chasing down hidden rewards.
              </div>
            </div>
          </div>
        </div>
        <div className="left-col card-project p-xl-4 p-md-3 px-4 rounded-24 d-flex    py-4">
          <div className="first-child d-flex">
            <div className="mt-auto">
              <img src={smile} className="mb-3 img-fluid" alt="" />

              <Title title="Collectibles" size={32} />
              <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-3">
                Find, acquire, or collect items as you progress through games to
                enhance your experience and showcase your talents and abilities.
              </div>
            </div>
          </div>
        </div>
        <div className="left-col card-project p-xl-4 p-md-3 px-4 rounded-24 d-flex    py-4">
          <div className="first-child d-flex">
            <div className="d-flex">
              <div className="mt-auto">
                {" "}
                <div className="d-flex">
                  {" "}
                  <Tag
                    background="linear-gradient(45deg, #E0FF36, #FF9C42)"
                    color="#000"
                    uppertitle="UNIQUE"
                  />
                </div>
                <Title title="Skill Games" size={32} />
                <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-2">
                  Use your abilities, strategies, and decision-making to
                  succeed, rather than relying purely on luck or chance.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="left-col card-project p-xl-4 p-md-3 px-4 rounded-24 d-flex    py-4">
          <div className="first-child d-flex">
            <div className="mt-auto">
              <img src={market} className="mb-3 img-fluid" alt="" />

              <Title title="Predict the Future" size={32} />
              <div className="font-size-18 gray-color font-iner-regulat line-height-1-2 mt-3">
                Predict upcoming events or outcomes based on certain inputs,
                data, or simulations by combining elements of strategy,
                knowledge, and chance, with the goal of making correct
                predictions to earn rewards or points.
              </div>
            </div>
          </div>
        </div>
        <div className="left-col card-project p-xl-4 p-md-3 px-4 rounded-24 d-flex    py-4 background-lipo-gradient">
          <div className="first-child d-flex">
            <div className="mt-auto">
              <img src={built} className="mb-3 img-fluid" alt="" />
              <Title title="Start NOW" size={32} />
            </div>
          </div>
        </div>
      </SliderRight>
    </section>
  );
};

export default MobileCarousel;
