import React from "react";
import "./Homepage.scss";
import Hero from "./HomepageHero/HomepageHero";

import Footer from "../../Components/Footer/Footer";

import FirstProject from "./FirstProject/FirstProject";

import img1 from "./gameslayers/game-thumb-1.png";
import img2 from "./gameslayers/game-thumb-2.png";
import img3 from "./gameslayers/game-thumb-3.png";
import img4 from "./gameslayers/game-thumb-4.png";
import img5 from "./gameslayers/game-thumb-5.png";
import img6 from "./gameslayers/game-thumb-6.png";
import img7 from "./gameslayers/game-thumb-7.png";
import img8 from "./gameslayers/game-thumb-8.png";
import img9 from "./gameslayers/game-thumb-9.png";
import img10 from "./gameslayers/game-thumb-10.png";
import img11 from "./gameslayers/game-thumb-11.png";
import img12 from "./gameslayers/game-thumb.png";
import { ReactComponent as Diamond } from "./gameslayers/diamond-01.svg";
import { ReactComponent as HotGameIcon } from "./gameslayers/icon.svg";
import carImg from "./gameslayers/card-img.png";

import game1 from "./gameslayers/1.png";
import game2 from "./gameslayers/2.png";
import game3 from "./gameslayers/3.png";
import game4 from "./gameslayers/4.png";
import game5 from "./gameslayers/5.png";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import CardComponent from "./CardComponent";
import PlatformForComunity from "./PlatformForComunity/PlatformForComunity";
import SliderComp from "../../Components/Slider/SliderComp";
import Mechanics from "../TokenPage/Mechanics/Mechanics";
import ButtonOutline from "../../Components/ButtonOutline/ButtonOutline";
const Homepage = () => {
  const sliderData = [
    {
      id: 4,
      image: (
        <img
          src={img4}
          loading="lazy"
          alt="Wallet and inventory"
          className="slide-image"
        />
      ),
      className: "slide-image mx-2 p-0",
    },
    {
      id: 4,
      image: (
        <img
          src={img1}
          loading="lazy"
          alt="Wallet and inventory"
          className="slide-image"
        />
      ),
      className: "slide-image mx-2 p-0",
    },
    {
      id: 4,
      image: (
        <img
          src={img2}
          loading="lazy"
          alt="Wallet and inventory"
          className="slide-image"
        />
      ),
      className: "slide-image mx-2 p-0",
    },
    {
      id: 4,
      image: <img src={img3} alt="img3" className="img-fluid" />,
      className: "slide-image mx-2 p-0",
    },
    {
      id: 4,
      image: (
        <img
          src={img4}
          loading="lazy"
          alt="Wallet and inventory"
          className="slide-image"
        />
      ),
      className: "slide-image mx-2 p-0",
    },
    {
      id: 4,
      image: <img src={img5} alt="img3" className="img-fluid" />,
      className: "slide-image mx-2 p-0",
    },
    {
      id: 4,
      image: <img src={img6} alt="img3" className="img-fluid" />,
      className: "slide-image mx-2 p-0",
    },
    {
      id: 4,
      image: <img src={img7} alt="img3" className="img-fluid" />,
      className: "slide-image mx-2 p-0",
    },
    {
      id: 4,
      image: <img src={img8} alt="img3" className="img-fluid" />,
      className: "slide-image mx-2 p-0",
    },
    {
      id: 4,
      image: <img src={img9} alt="img3" className="img-fluid" />,
      className: "slide-image mx-2 p-0",
    },
    {
      id: 4,
      image: <img src={img10} alt="img3" className="img-fluid" />,
      className: "slide-image mx-2 p-0",
    },
    {
      id: 4,
      image: <img src={img11} alt="img3" className="img-fluid" />,
      className: "slide-image mx-2 p-0",
    },
    {
      id: 4,
      image: <img src={img12} alt="img3" className="img-fluid" />,
      className: "slide-image mx-2 p-0",
    },
  ];
  return (
    <div className="homepage white-color s">
      <Hero />
      <FirstProject />
      <Mechanics />

      <PlatformForComunity />
      <div className="font-iner-bold font-size-40 white-color line-height-1 my-5">
        <div className="large-container ">
          <h2 className="mb-5 font-iner-bold font-size-40 white-color line-height-1">
            <Diamond />
            Featured Games
          </h2>

          <div className="row">
            <div className="col-lg-7 col-12 mb-3 mb-lg-0">
              <div className="position-relative d-flex justify-content-center align-items-center ">
                <ButtonOutline
                  title="Learn More"
                  height={48}
                  heightResponsive={48}
                  heightResponsive580={40}
                  width={150}
                  color="#fff"
                  outlineColor="transparent"
                  bgColor="#272727c4"
                  hoverColor="#fff"
                  bgHoverColor="#E169A0"
                  customClass="z-2 radius64 font-ri-m font-size-20 me-3 position-absolute abs-button-center"
                  callback={(e) => window.open("https://play.livepot.com")}
                />
                <img src={carImg} alt="img1" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-5 col-12 d-flex flex-column gap-3 justify-content-between">
              <div
                onClick={() => window.open("https://play.livepot.com")}
                className="d-flex flex-row justify-content-between grey-card p-2 flex-grow-1 cursor-pointer"
              >
                <div className="d-flex flex-row gap-3 w-fill">
                  <img src={game1} alt="img1" className="img-fluid" />
                  <div className="mt-3">
                    <h3 className="font-size-24 font-iner-bold mb-2">
                      Mines Game
                    </h3>
                    <p className="font-size-16 font-iner-regular subtitle-text">
                      Arcade Single Player
                    </p>
                  </div>
                </div>
                <FiChevronRight size={24} className="ms-auto my-auto" />
              </div>
              <div
                onClick={() => window.open("https://play.livepot.com")}
                className="d-flex flex-row justify-content-between grey-card p-2 flex-grow-1 cursor-pointer"
              >
                <div className="d-flex flex-row gap-3 w-fill">
                  <img src={game2} alt="img1" className="img-fluid" />
                  <div className="mt-3">
                    <h3 className="font-size-24 font-iner-bold mb-2">
                      Book of Demi Gods
                    </h3>
                    <p className="font-size-16 font-iner-regular subtitle-text">
                      Arcade Single Player
                    </p>
                  </div>
                </div>
                <FiChevronRight size={24} className="ms-auto my-auto" />
              </div>
              <div
                onClick={() => window.open("https://play.livepot.com")}
                className="d-flex flex-row justify-content-between grey-card p-2 flex-grow-1 cursor-pointer"
              >
                <div className="d-flex flex-row gap-3 w-fill">
                  <img src={game3} alt="img1" className="img-fluid" />
                  <div className="mt-3">
                    <h3 className="font-size-24 font-iner-bold mb-2">
                      Aztec Coins
                    </h3>
                    <p className="font-size-16 font-iner-regular subtitle-text">
                      Arcade Single Player
                    </p>
                  </div>
                </div>
                <FiChevronRight size={24} className="ms-auto my-auto" />
              </div>
              <div
                onClick={() => window.open("https://play.livepot.com")}
                className="d-flex flex-row justify-content-between grey-card p-2 flex-grow-1 cursor-pointer"
              >
                <div className="d-flex flex-row gap-3 w-fill">
                  <img src={game4} alt="img1" className="img-fluid" />
                  <div className="mt-3">
                    <h3 className="font-size-24 font-iner-bold mb-2">
                      Book of Rampage
                    </h3>
                    <p className="font-size-16 font-iner-regular subtitle-text">
                      Arcade Single Player
                    </p>
                  </div>
                </div>
                <FiChevronRight size={24} className="ms-auto my-auto" />
              </div>
              <div
                onClick={() => window.open("https://play.livepot.com")}
                className="d-flex flex-row justify-content-between grey-card p-2 flex-grow-1 cursor-pointer"
              >
                <div className="d-flex flex-row gap-3 w-fill  ">
                  <img src={game5} alt="img1" className="img-fluid" />
                  <div className="mt-3">
                    <h3 className="font-size-24 font-iner-bold mb-2">
                      Vikings Gods II
                    </h3>
                    <p className="font-size-16 font-iner-regular subtitle-text">
                      Arcade Single Player
                    </p>
                  </div>
                </div>
                <FiChevronRight size={24} className="ms-auto my-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="font-iner-bold font-size-40 white-color line-height-1 my-5">
        <div className="large-container ">
          <h2 className="mb-5 font-iner-bold font-size-40 white-color line-height-1">
            <HotGameIcon />
            Hot Games
          </h2>

          <div className="game-grid-container d-none d-lg-block">
            <div className="row gx-4 gy-4 justify-content-center">
              <div
                className="col-6 col-sm-4 col-lg-2"
                onClick={() => window.open("https://play.livepot.com")}
              >
                <img src={img1} alt="img1" className="img-fluid" />
              </div>
              <div
                className="col-6 col-sm-4 col-lg-2"
                onClick={() => window.open("https://play.livepot.com")}
              >
                <img src={img2} alt="img2" className="img-fluid" />
              </div>
              <div
                className="col-6 col-sm-4 col-lg-2"
                onClick={() => window.open("https://play.livepot.com")}
              >
                <img src={img3} alt="img3" className="img-fluid" />
              </div>
              <div
                className="col-6 col-sm-4 col-lg-2"
                onClick={() => window.open("https://play.livepot.com")}
              >
                <img src={img4} alt="img4" className="img-fluid" />
              </div>
              <div
                className="col-6 col-sm-4 col-lg-2"
                onClick={() => window.open("https://play.livepot.com")}
              >
                <img src={img5} alt="img5" className="img-fluid" />
              </div>
              <div
                className="col-6 col-sm-4 col-lg-2"
                onClick={() => window.open("https://play.livepot.com")}
              >
                <img src={img6} alt="img6" className="img-fluid" />
              </div>
              <div
                className="col-6 col-sm-4 col-lg-2"
                onClick={() => window.open("https://play.livepot.com")}
              >
                <img src={img7} alt="img7" className="img-fluid" />
              </div>
              <div
                className="col-6 col-sm-4 col-lg-2"
                onClick={() => window.open("https://play.livepot.com")}
              >
                <img src={img8} alt="img8" className="img-fluid" />
              </div>
              <div
                className="col-6 col-sm-4 col-lg-2"
                onClick={() => window.open("https://play.livepot.com")}
              >
                <img src={img9} alt="img9" className="img-fluid" />
              </div>
              <div
                className="col-6 col-sm-4 col-lg-2"
                onClick={() => window.open("https://play.livepot.com")}
              >
                <img src={img10} alt="img10" className="img-fluid" />
              </div>
              <div
                className="col-6 col-sm-4 col-lg-2"
                onClick={() => window.open("https://play.livepot.com")}
              >
                <img src={img12} alt="img10" className="img-fluid" />
              </div>
              <div
                className="col-6 col-sm-4 col-lg-2"
                onClick={() => window.open("https://play.livepot.com")}
              >
                <img src={img11} alt="img10" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="slider-component position-relative d-lg-none">
            <SliderComp data={sliderData} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
