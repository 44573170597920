import React from "react";
import "./Footer.scss";
import MainLogo from "./Assets/LivePotLogo.png";
import cryptodata from "./Assets/developed-by.png";

import Title from "../Title/Title";

import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { routes } from "../../Globals/routes";
import axios from "axios";
import { toast } from "react-toastify";
import { socialMedia } from "../../Globals/socialMedia";
import { MdFacebook, MdOutlineFacebook } from "react-icons/md";
import { FaDiscord, FaTiktok } from "react-icons/fa";
import facebook from "./Assets/facebook.png";
import twitter from "./Assets/twitter.png";
import instagram from "./Assets/instagram.png";
import medium from "./Assets/medium.png";
import youtube from "./Assets/youtube.png";

const Footer = () => {
  const [email, setEmail] = React.useState("");
  const [success, setSuccess] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://subscribe.livepot.com/subscribers/subscribeToNewsletter",
        {
          email: email,
        }
      );
      if (response.status === 200) {
        toast.success("Thank you for subscribing to our newsletter");
        setSuccess(true);
      }
    } catch (error) {
      switch (error.response.status) {
        case 401:
          toast.warning("Please enter a valid email address!");
          break;
        case 409:
          toast.warning("Email already subscribed!");
          break;
        default:
          toast.warning("Oups, something went wrong!");
      }
    }
  };

  const navigate = useNavigate();
  return (
    <div id="footer" className="">
      <div className="large-container ">
        <div className="text-center">
          <div className="d-flex flex-column">
            <div>
              {" "}
              <img
                src={MainLogo}
                alt="main-logo"
                className="img-fluid mb-4 main-logo mt-3"
              />
            </div>
          </div>
          <div
            className="font-size-18 fonr-ti-r mt-3 light-gray-color text-center lh-13 m-auto mb-5"
            style={{ maxWidth: "650px" }}
          >
            Stay up-to-date with our latest achievements, behind-the-scenes
            stories, and expert insights from the world of LivePot.
          </div>
          {!success && (
            <div className="d-flex justify-content-center mb-5">
              {" "}
              <div
                style={{ maxWidth: "650px" }}
                className="position-relative w-100"
              >
                {" "}
                <form onSubmit={handleSubmit}>
                  <input
                    placeholder="enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    className="input-rnf input-subscribe   "
                  />
                  <Button
                    title="Send"
                    height={48}
                    heightResponsive={48}
                    heightResponsive580={40}
                    width={100}
                    color="#fff"
                    outlineColor="transparent"
                    bgColor="linear-gradient(90deg, #FF9C42 0%,  #C236FF 100%) 0% 0% no-repeat padding-box "
                    hoverColor="#fff"
                    bgHoverColor="#08FF9B"
                    customClass="z-2 radius64 font-ri-m font-size-20 position-absolute button-center"
                    callback={(e) => handleSubmit(e)}
                  />
                </form>
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className="row  mt-3 ">
          <div className=" col-md-3 col-12 white-color font-iner-regular  d-flex justify-content-md-start justify-content-center">
            <div>Copyright © LivePot.com</div>
          </div>
          <div className="col-md-7 col-12  ">
            <div className="row d-flex h-100 justify-content-center align-items-center">
              <div
                onClick={(e) => navigate(routes.tAndCVirtual)}
                className="white-color text-center col-md-4 col-12 cursor-pointer mt-md-0 mt-4"
              >
                Terms & Conditions Virtual
              </div>
              <div
                onClick={(e) => navigate(routes.tAndC)}
                className="white-color text-center col-md-4 col-12 cursor-pointer mt-md-0 mt-4"
              >
                Terms & Conditions
              </div>
              <div
                onClick={(e) => navigate(routes.privacyPolicy)}
                className="white-color text-center col-md-4 col-12 cursor-pointer mt-md-0 mt-4"
              >
                Privacy Policy
              </div>
            </div>
          </div>
          <div className="socials col-md-2 col-12 d-flex justify-content-md-end mt-md-0 mt-4 justify-content-center justify-content-md-end opacity-0">
            <a
              href={socialMedia.facebook}
              target="_blank"
              className="white-color font-ri-r font-size-18 me-md-3 me-4"
            >
              <img src={facebook} className="img-fluid " alt="" />
            </a>
            <a
              href={socialMedia.instagram}
              target="_blank"
              className="white-color font-ri-r font-size-18 me-md-3 me-4"
            >
              <img src={instagram} className="img-fluid " alt="" />
            </a>

            <a
              href={socialMedia.twitter}
              target="_blank"
              className="white-color font-ri-r font-size-18 me-md-3 me-4"
            >
              <img src={twitter} className="img-fluid " alt="" />
            </a>
            <a
              href={socialMedia.youtube}
              target="_blank"
              className="white-color font-ri-r font-size-18 me-md-3 me-4"
            >
              <img src={youtube} className="img-fluid " alt="" />
            </a>
            <a
              href={socialMedia.tiktok}
              target="_blank"
              className="white-color font-ri-r font-size-18 me-md-3 me-4"
            >
              <FaTiktok />
            </a>
            <a
              href={socialMedia.discord}
              target="_blank"
              className="white-color font-ri-r font-size-18 me-md-3 me-4"
            >
              <FaDiscord />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
