import React from "react";
import Footer from "../../Components/Footer/Footer";

export default function TC() {
  return (
    <div className="container white-color pt-5">
      <h1 className="text-center font-iner-bold font-size-40 mt-5">
        {" "}
        Terms and Conditions for Gaming Services
      </h1>
      <br></br>
      1. LIVEPOT.COM <br></br>
      <br></br>1.1 Livepot.com is owned and operated LIMITLESS PAY CO., LTD, a
      limited liability company established under the laws of Lao PDR,
      registered in accordance with Enterprise Law No. 33/NPC dated December 29,
      2022, under Enterprise Code Number and Taxpayer Identification Number
      472667112-000, with registered capital of 1,000,000,000 kip, having its
      registered office at Phon Kham Village, Sikottabong District, Vientiane,
      Lao PDR . Livepot.com is an online gaming platform offering exclusively
      skill-based games. These games are designed purely for recreational and
      entertainment purposes. The platform explicitly excludes any form of
      gambling or betting activities. Participation on the Livepot.com website
      and the acquisition of virtual items do not represent an investment,
      financial opportunity, or financial instrument of any kind. Livepot.com
      provides no guarantee whatsoever of winnings, returns, or financial gain
      from using the services.
      <br></br>2. IMPORTANT NOTICE
      <br></br>2.1 By registering on www.Livepot.com (the “Website”), you enter
      into a contract with LIMITLESS PAY CO., LTD, and agree to be bound by (i)
      these Terms and Conditions; (ii) our Privacy Policy; and (iii) the Virtual
      Items Terms and Conditions (“Terms and Conditions” or “Agreement”), and
      the gaming specific rules, and are deemed to have accepted and understood
      all the terms. 2.2 Please read this Agreement carefully to make sure you
      fully understand its content. If you have any doubts as to your rights and
      obligations resulting from the acceptance of this Agreement, please
      consult a legal advisor in your jurisdiction before further using the
      Website(s) and accessing its content. If you do not accept the terms, do
      not use, visit or access any part (including, but not limited to,
      sub-domains, source code and/or website APIs, whether visible or not) of
      the Website.
      <br></br>3. GENERAL
      <br></br>3.1 When registering on Livepot.com You (“You”, “Your”, Yourself”
      or the “User” interchangeably) enter into an agreement with Livepot.
      <br></br>3.2 This Agreement should be read by You in its entirety prior to
      your use of Livepot's service or products. Please note that the Agreement
      constitutes a legally binding agreement between you and Livepot.
      <br></br>3.3 These Terms and Conditions come into force as soon as you
      complete the registration process, which includes checking the box
      accepting these Terms and Conditions and successfully creating an account.
      By using any part of the Website following account creation, you agree to
      these Terms and Conditions applying to the use of the Website.
      <br></br>3.4 We are entitled to make amendments to these Terms and
      Conditions at any time and without advanced notice. If we make such
      amendments, we may take appropriate steps to bring such changes to your
      attention (such as by email or placing a notice on a prominent position on
      the Website, together with the amended terms and conditions) but it shall
      be your sole responsibility to check for any amendments, updates and/or
      modifications. Your continued use of the website services after any such
      amendment to the Terms and Conditions will be deemed as your acceptance
      and agreement to be bound by such amendments, updates and/or
      modifications.
      <br></br>3.5 The terms of this Terms and Conditions shall prevail in the
      event of any conflict between the terms of this Terms and Conditions and
      of any of the game rules or other documents referred to in this Terms and
      Conditions.
      <br></br>3.6 These Terms and Conditions may be published in several
      languages for informational purposes and ease of access by Users. The
      English version is the only legal basis of the relationship between you
      and us and in the case of any discrepancy with respect to a translation of
      any kind, the English version of these Terms and Conditions shall prevail.
      <br></br>4. SERVICES AND USE OF GAMES
      <br></br>Access and use of Services
      <br></br>4.1. Livepot provides Users with access to skill-based games
      available on our platform, designed primarily to test the Users' skills,
      knowledge, strategies, and proficiency. These Games explicitly exclude
      gambling or betting activities.
      <br></br>4.2. Our Services include the provision of Games and related
      software, facilitating User interaction, gameplay, virtual item purchases.
      <br></br>4.3. Livepot reserves the right to update, modify, temporarily
      suspend, or discontinue any part or all of the Services at any time,
      without prior notice, at our sole discretion.
      <br></br>Access to Services
      <br></br>4.4. To access and fully utilize our Services, Users may need to
      register an account, agree to our Terms and Conditions, and comply with
      the Virtual Items Terms and any other applicable policies.
      <br></br>4.5. Livepot may restrict access to certain Games, Virtual Items,
      or Services based on a User’s location, age, compliance status, or any
      other relevant factor as required by applicable laws, regulations, or
      internal policies.
      <br></br>4.6. Livepot grants you a limited, revocable, personal license to
      access and play Games provided as part of our Services, subject to your
      acquisition and use of Coins as outlined in our Virtual Items Terms.
      <br></br>4.7. Your access to and participation in Games are governed by
      these Terms and Conditions, the Virtual Items Terms, and any other game
      rules or guidelines published by Livepot in connection with our Services.
      <br></br>Skill-based gameplay
      <br></br>4.8. All Games accessible through our Services are strictly
      skill-based. Outcomes in these Games rely predominantly on your skills,
      knowledge, strategies, and experience, and explicitly exclude any reliance
      on chance or randomness. The platform utilizes advanced artificial
      intelligence to randomly generate independent gaming instances for each
      user, ensuring a varied, interactive, and individualized gaming
      experience. The AI-driven randomness is specifically designed to enhance
      users' skills, reflexes, and gameplay abilities by preventing repetitive
      behavior.
      <br></br>4.9. Participation in Games provided through our Services does
      not constitute gambling or betting, and Users will not receive monetary
      winnings, financial returns, or any valuable consideration other than
      entertainment value.
      <br></br>Use of Coins within Games
      <br></br>4.10. To access and engage with Games provided within our
      Services, Users must utilize Coins. Coins represent virtual,
      non-transferable tokens without monetary value and are governed by the
      Virtual Items Terms.
      <br></br>4.11. All provisions relating to the acquisition, management,
      transfer, expiration, or usage of Coins in Games or other aspects of our
      Services are set out explicitly within the Virtual Items Terms.
      <br></br>5. LIVEPOT ACCOUNT
      <br></br>Registration 5.1 In order for you to use the Services and
      subsequently the Games on Livepot.com, you must first personally register
      an account with us ("Livepot Account").
      <br></br>5.2 For a person to be registered as a User with Livepot and use
      the Website, that person must submit an application for registration and
      opening of a Livepot account. The application for the opening of the
      Livepot Account must be submitted personally, and will require You to
      provide a set of personal information, namely e-mail, full name, date of
      birth, address, etc.
      <br></br>5.3 Where the information stipulated in 5.2. is not provided
      and/or is not deemed to be complete, accurate or up-to-date at any point
      in time, Livepot reserves the right to suspend the Livepot Account
      registration and treat any subsequent potentially accepted deposits to the
      User’s Livepot Account as invalid (and any winnings arising from such
      deposit as void). Where a Livepot Account is suspended, You should contact
      customer support at the e-mail addresses provided on the website .
      <br></br>5.4 All applicants must be 18 or such other legal age of majority
      as determined by any laws which are applicable to you, whichever age is
      greater or older. Livepot reserves the right to ask for proof of age from
      any User and suspend their Livepot Account until satisfactory
      documentation is provided.
      <br></br>5.5 Livepot will not accept registration from individuals:
      <br></br>a) Under 18 years old or under the legal age of majority in their
      jurisdiction;
      <br></br>b) Residing in jurisdictions from where Livepot is not able to
      verify the legality of the Service in each jurisdiction and it is the
      User's responsibility to ensure that their use of the Service is lawful;
      <br></br>c) Provide misleading information or try to pass by third
      parties.
      <br></br>5.6 Livepot reserves the right to refuse any application for a
      Livepot Account, at its sole discretion.
      <br></br>Know Your Customer
      <br></br>5.7 You represent and warrant that any information provided by
      You on Your application form is true, updated and correct.
      <br></br>5.8 Livepot reserves the right, at any time, to ask for any KYC
      documentation it deems necessary to determine the identity and location of
      a User. Livepot reserves the right to restrict the Service, purchase or
      withdrawal until identity is sufficiently determined, or for any other
      reason in Livepot’s sole discretion. Livepot also reserves the right to
      disclose a User’s information as appropriate to comply with legal process
      or as otherwise permitted by the privacy policy of Livepot (owner and
      operator of Livepot), and by using the Service, you acknowledge and
      consent to the possibility of such disclosure.
      <br></br>Multiple Accounts
      <br></br>5.9 Only one Livepot Account per User is allowed. Should You
      attempt or successfully open more than one Livepot Account, under Your own
      name or under any other name, or should You attempt or succeed in using
      the Website by means of any other person's Livepot Account, Livepot will
      be entitled to immediately close all Your Livepot Account(s), retain all
      monies in such Livepot Accounts and ban You from future use of the
      Website.
      <br></br>5.10 Should Livepot have reason to believe that You have
      registered and/or used more than one Livepot Account, or colluded with one
      or more other individuals using a number of different Livepot Accounts,
      Livepot shall be entitled to deem such accounts as constituting multiple
      Livepot Accounts, and suspend or close all such Livepot Accounts. Livepot
      will also be entitled to retain the Coins till the User proves that he did
      not attempt to create multiple accounts.
      <br></br>5.11 If you notice that you have more than one registered Livepot
      Account you must notify us immediately. Failure to do so may lead to your
      Livepot Account being blocked and the Coins retained.
      <br></br>User Responsibility
      <br></br>5.12 It is your sole and exclusive responsibility to ensure that
      your login details are kept securely. You must not disclose your login
      details to anyone.
      <br></br>5.13 We are not liable or responsible for any abuse or misuse of
      your Livepot Account by third parties due to your disclosure, whether
      intentional, accidental, active or passive, of your login details to any
      third party.
      <br></br>5.14 You are prohibited from selling, transferring or acquiring
      Livepot Accounts to or from other Users.
      <br></br>5.15 You will inform us as soon as you become aware of any errors
      with respect to your account or any calculations with respect to any bet
      you have placed. We reserve the right to declare null and void any bets
      that are subject to such an error.
      <br></br>5.16 You are responsible for all activities that occur under your
      account.
      <br></br>Suspension and Closure by Livepot
      <br></br>5.17 Livepot shall be entitled to close or suspend Your Livepot
      Account if:
      <br></br>a) Livepot considers that You are using or have used the Website
      in a fraudulent or collusive manner or for illegal and/or unlawful or
      improper purposes;
      <br></br>b) Livepot considers that You are using or have used the Website
      in an unfair manner, have deliberately cheated or taken unfair advantage
      of Livepot or any of its customers or if Your Livepot Account is being
      used for the benefit of a third party;
      <br></br>c) Livepot is requested to do so by the police, any regulatory
      authority or court or if Livepot is unable to verify Your identity,
      profession or source of Coins as is expressly required by the applicable
      regulations;
      <br></br>d) You are in breach of these Terms and Conditions, the
      applicable regulations or the fair use of our services, or Livepot has
      concerns that You are a compulsive problem gambler without being
      self-excluded;
      <br></br>e) Livepot considers that any of the events referred to in (a) to
      (e) above may have occurred or are likely to occur.
      <br></br>5.18 If Livepot closes or suspends Your Livepot Account for any
      of the reasons referred to in 5.17, You shall, to the extent permitted by
      applicable laws, be liable for any and all claims, direct losses,
      liabilities, damages, costs and expenses incurred or suffered by Livepot
      (together, the “Claims”) arising therefrom and shall, to the extent
      permitted by applicable laws, indemnify and hold Livepot harmless on
      demand for such Claims.
      <br></br>5.19 In the circumstances referred to in 4.19, Livepot shall also
      be entitled to void any bets placed by You following such actions by You
      or to withhold and/or retain any and all amounts which would otherwise
      have been paid or payable to you (including any winnings) to the extent
      permitted by law.
      <br></br>5.20 We reserve the right to suspend or terminate your account at
      any time, with or without notice, if we suspect that your account has been
      compromised or is being used in breach of our Terms of Service.
      <br></br>5.21 If Livepot closes Your Livepot Account it will inform You of
      the available means to withdraw the remaining balance on Your Livepot
      Account.
      <br></br>Our Liability
      <br></br>5.22 We take no responsibility for any loss or damage that you
      may suffer as a result of unauthorised access to your account.
      <br></br>5.23 We take no responsibility for any loss or damage that you
      may suffer as a result of your failure to keep your login secure and
      private.
      <br></br>6. YOUR WARRANTIES
      <br></br>6.1 Prior to your use of the Service and on an ongoing basis you
      represent, warrant, covenant and agree that:
      <br></br>Capacity
      <br></br>a) You are over 18 or such other legal age of majority as
      determined by any laws which are applicable to you, whichever age is
      greater;
      <br></br>b) You have full capacity to enter into a legally binding
      agreement with us and you are not restricted by any form of limited legal
      capacity;
      <br></br>Jurisdiction
      <br></br>a) You are accessing Livepot.com from a jurisdiction in which it
      is legal to do so;
      <br></br>b) You will not use our services while located in any
      jurisdiction that prohibits the placing and/or accepting of bets online
      and/or playing casino and/or live games;
      <br></br>c) You accept and acknowledge that we reserve the right to detect
      and prevent the use of prohibited techniques, including but not limited to
      fraudulent transaction detection, automated registration and signup,
      gameplay and screen capture techniques. These steps may include, but are
      not limited to, examination of Users device properties, detection of
      geo-location and IP masking, transactions and blockchain analysis;
      <br></br>Coins to use Services
      <br></br>(a) You acknowledge that Coins purchased to play Games in Livepot
      constitute play Coins without real monetary or exchange value and their
      use is subject to the Virtual Items Terms;
      <br></br>(b) You are solely responsible for complying with any applicable
      taxes or legal obligations arising from your use of the Service, depending
      on your local jurisdiction and laws;
      <br></br>(c) Coins purchased for the use of Livepot cannot be exchanged,
      refunded, or redeemed for fiat currency, cryptocurrency, or any tangible
      goods or services outside of the platform;
      <br></br>(d) You represent and warrant that any Coins used to purchase
      Coins originate from lawful and authorized sources and do not derive from
      criminal or unauthorized activities;
      <br></br>(e) You agree that Coins acquired through the platform can only
      be used within the Services provided on Livepot;
      <br></br>(f) You may withdraw Coins exclusively to your personal wallet
      within the Livepot ecosystem, but we cannot guarantee for you to withdraw
      Coins to external purchase methods, external wallets, or convert them back
      into fiat or cryptocurrencies;
      <br></br>(g) You explicitly acknowledge and accept that using Coins within
      the platform is solely for entertainment purposes, carries no financial
      investment value, and involves no possibility of cash redemption;
      <br></br>(h) Livepot is not a financial institution, gambling website, or
      cryptocurrency exchange, and Coins used within our platform do not
      constitute an investment, gambling stakes, or any other form of financial
      instrument.
      <br></br>Others
      <br></br>a) Your use of the Service is at your sole option, discretion and
      risk;
      <br></br>b) You will not conduct criminal activities through the Livepot
      Account;
      <br></br>c) All information that you provide to us during the term of
      validity of this agreement is true, complete, correct, and that you shall
      immediately notify us of any change of such information;
      <br></br>d) You participate in the Games strictly in your personal and
      non-professional capacity and participate for recreational and
      entertainment purposes only;
      <br></br>e) You participate in the Games on your own behalf and not on the
      behalf of any other person;
      <br></br>f) You have only one account with us and agree to not to open any
      more accounts with us;
      <br></br> g) The telecommunications networks and Internet access services
      required for you to access and use the Service are entirely beyond the
      control of Livepot and Livepot shall have no liability whatsoever for any
      outages, slowness, capacity constraints or other deficiencies affecting
      the same;
      <br></br>h) You will not be involved in any fraudulent, collusive, fixing
      or other unlawful activity in relation to Your or any third parties’
      participation in any of the games and/or services on the Website, and
      shall not use any software-assisted methods or techniques or hardware
      devices for Your participation in any of the games and/or services on the
      Website;
      <br></br>6.2 In case of a breach of any of the representations, warrants
      or covenants mentioned in 6.1, Livepot reserves the right to close or
      suspend Your Livepot account at its own discretion and void any bets to
      the extent applicable by law.
      <br></br>7. LIVEPOT WARRANTIES
      <br></br>7.1 The software is provided "as is" without any warranties,
      conditions, undertakings or representations, express or implied, statutory
      or otherwise. Livepot hereby excludes all implied terms, representations,
      conditions and warranties (including any of merchantability, merchantable
      quality, satisfactory quality and fitness for any particular purpose).
      Livepot does not warrant that: (i) the Website and Services will meet Your
      requirements; (ii) the Website and Services will not infringe any third
      party’s intellectual property rights; (iii) the operation of the Website
      and Services will be error-free or uninterrupted; (iv) any defects in the
      Website and Services will be corrected; or (v) the Website or the servers
      are virus-free. While Livepot.com aims to maintain continuous uptime and
      availability of the platform, no guarantee can be provided regarding 100%
      uninterrupted service or perpetual accessibility. The platform explicitly
      disclaims responsibility for any disruptions, downtime, or losses
      resulting from platform availability issues.
      <br></br>7.2 Livepot can be contacted by email. In the event that Livepot,
      in its sole discretion, deems that Your behaviour, via, email, or
      otherwise, has been abusive or derogatory towards any of Livepot’s or its
      Affiliates or third-party service provider’s employees, Livepot shall have
      the right to block or terminate Your Livepot Account.
      <br></br>8. DEPOSITS
      <br></br>8.1 You may participate in any Game only if you have sufficient
      Coins on your Livepot Account for such participation. For that purpose you
      shall use the purchase methods available on the Website to deposit your
      Coins. Livepot will not give you any credit whatsoever for participation
      in any Game.
      <br></br>8.2 You must deposit Coins to Your Livepot Account using the
      purchase methods available at Livepot.com .<br></br>8.3 You shall ensure
      that Coins that You deposit into your Livepot Account are not tainted with
      any illegality and, in particular, do not originate from any illegal
      activity or source.
      <br></br>8.4 To deposit Coins into your Livepot Account, you can transfer
      Coins from crypto-wallets under your control or through any other purchase
      methods available on Livepot.com. Deposits can only be made with your own
      Coins.
      <br></br>8.5 You should only deposit Coins into Your account for the
      purpose of You using such Coins to use Services and play Games on the
      Website. Livepot is entitled to suspend or close Your account if we
      reasonably believe that You are depositing Coins without any intention to
      use the Services. In such circumstances we may also report this activity
      to relevant authorities.
      <br></br>8.6 Livepot can set at its own discretion a minimum deposit
      amount. The minimum deposit amount can be changed at all time at Livepot’s
      discretion and will be identified on the website. Please be aware that
      depending on the purchase method used by You, additional fees might be
      charged by the purchase providers.
      <br></br>8.7 The purchase methods made available to you and the minimum
      and maximum deposit limit can be found in the wallet section on the Site.
      Applicable service fees may be applied and changed. Some purchase methods
      may not be available in all countries.
      <br></br>8.8 Deposits are immediately processed and the updated balance is
      shown in the Livepot Account instantly whenever a purchase service
      provider is used. Livepot does not take responsibility for any delays
      caused due to wallet transfer system, purchase system or due to delays
      caused by any third party. Note that:
      <br></br>a) some purchase methods may include additional fees.
      <br></br>b) the purchase service provider may charge you additional fees
      for deposits of currency conversion according to their terms and
      conditions and your user agreement.
      <br></br>8.9 We reserve the right to use additional procedures and means
      to verify your identity when processing deposits into your Livepot
      Account.
      <br></br>8.10 Coins cannot be transferred from your Livepot Account to the
      Livepot Account of another User.
      <br></br>8.11 Livepot can refuse any deposits at its own discretion. Users
      that have their accounts blocked, or suspended shall refrain from
      depositing at Livepot.com .<br></br>8.12 In the event that a User tries to
      deposit when his account is blocked or suspended, Livepot will have the
      right to retain the Coins.
      <br></br>9. WITHDRAWALS
      <br></br>9.1 Livepot reserves the right to refuse any withdrawal by a User
      from their Livepot Account until:
      <br></br>9a) the User’s identity has been verified and Livepot has
      confirmed the withdrawal is being made by a holder of the Livepot Account;
      <br></br>9b) the withdrawal is being transferred to an account of which
      the User is a legal holder;
      <br></br>c) any additional information requested by Livepot has been
      provided; and d<br></br>) the User has complied with the minimum wager
      requirement for each deposit.
      <br></br>9.2 Livepot reserves all rights to investigate Your account and
      gaming activity. If Livepot reasonably suspects that Your account or
      gaming activity has violated these terms of service or applicable laws or
      regulations, it may, in its sole discretion, delay or decline further
      deposits, withdrawals and/or game play while it conducts its
      investigation. You acknowledge and accept that Livepot may not be in a
      position to provide an explanation as to the nature of its investigation.
      <br></br>9.3 If we mistakenly credit your Livepot Account with winnings
      that do not belong to you, whether due to a technical error in the
      pay-tables, or human error or otherwise, the amount will remain our
      property and will be deducted from your Livepot Account. If you have
      withdrawn Coins that do not belong to you prior to us becoming aware of
      the error, the mistakenly paid amount will (without prejudice to other
      remedies and actions that may be available at law) constitute a debt owed
      by you to us. In the event of an incorrect crediting, you are obliged to
      notify us immediately by email. Withdrawals of Coins
      <br></br>9.4 Coins withdrawals will be made to your stated wallet address
      when making a valid withdrawal request.
      <br></br>9.5 Livepot reserves the right to carry out additional KYC
      verification procedures for any withdrawal. Users who wish to recover
      Coins held in a closed, locked or excluded account, are advised to contact
      the support of Livepot.
      <br></br>9.6 All transactions shall be checked in order to prevent Coins
      laundering. If a User becomes aware of any suspicious activity relating to
      any of the Games of the Website, (s)he must report this to Livepot
      immediately. Livepot may suspend, block or close a Livepot Account and
      withhold Coins if requested to do so in accordance with the Prevention of
      Coins Laundering Act or on any other legal basis requested by any state
      authority. You acknowledge that the Coins in your account are consumed
      instantly when playing and we do not provide return of goods, or
      retrospective cancellation of your account.
      <br></br>10. USER COINS PROTECTION & CHARGEBACKS
      <br></br>10.1 Any Coins You deposit with us in Your Livepot Account, along
      with any winnings, are held for You in separate customer wallet for the
      sole and specific purpose for You to use the Services and to settle any
      fees or charges that You might incur in connection with the use of our
      Services. This means Your Coins are protected from being used for any
      other purpose.
      <br></br>10.2 If we incur any charge-backs, reversals or denial of
      purchases or any loss suffered by Livepot as a consequence thereof due to
      causes attributable to You in respect of Your Livepot Account, we reserve
      the right to charge You for the relevant amounts incurred.
      <br></br>10.3 We may, at any time, offset any positive balance on Your
      account against any amounts owed by You to Livepot. Eligibility
      <br></br>10.4 Upon successful registration for a Livepot Account, You may
      be eligible to receive Livepot Promotions and Bonuses. By accepting this
      agreement and registering for a Livepot Account on the Website, You are
      also acknowledging and accepting to be bound by the rules and regulations
      associated with any Promotions and Bonuses offered to You by Livepot.
      Bonus T&C
      <br></br>10.5 All promotions, bonuses, or special offers are subject to
      the express terms of any bonus offered on the Website(s) and
      promotion-specific terms and conditions, if applicable, and any bonus
      credited to Your account must be used in adherence with such terms and
      conditions. By accepting a promotion, bonus, or special offer available on
      the Website(s), You consent to the terms and conditions of such promotion,
      bonus, or special offer and acknowledge that wagers must always be placed
      with cash balances before bonus balances can be used to wager. We reserve
      the right to withdraw any promotion, bonus, or special offer at any time.
      Activation & Expiry
      <br></br>10.6 By activating a Bonus or Promotion, You confirm that You
      also agree to the applicable terms and conditions. 10.7 No promotion,
      bonus, or special offer will be accepted or honoured by the Livepot
      following the expiration date of the promotion, bonus, or special offer,
      unless Livepot in its sole discretion chooses to do so for any particular
      customer, promotion, bonus, or special offer. Expiration dates will be set
      forth in the specific rules or terms and conditions of the particular
      promotion, bonus, or special offer. Furthermore, Livepot reserves the
      right, in its sole discretion, to change or modify any policy with respect
      to the earning or expiration of bonuses.
      <br></br>10.8 Once forfeited or de-activated, the Bonus will no longer be
      available to You (and cannot be re-activated at any time thereafter). The
      amount of any Bonus Coins that have already been credited to your Livepot
      Account Balance will remain available to You. Bonus Abuse & Fraud
      <br></br>10.9 In the event that Livepot believes a User of the Service is
      abusing or attempting to abuse a bonus or other promotion or is likely to
      benefit through abuse or lack of good faith from a policy adopted by
      Livepot, then Livepot may, at its sole discretion, deny, withhold, or
      withdraw from any User any bonus or promotion, or terminate that User’s
      access to the Services, the Software, and/or lock that User’s account,
      either temporarily or permanently.
      <br></br>0.10 You may only open one (1) account on the Website. The
      opening of multiple accounts on the Website for the purpose of
      accumulating bonuses, promotions, special offers, or otherwise, shall be
      considered abusive behaviour. Livepot Rights
      <br></br>10.10 Livepot reserves the right to remove bonuses from all
      inactive accounts or accounts that are identified as “bonus abusers”.
      <br></br>10.11 Livepot reserves the right to cancel all bonuses that have
      not been claimed within the claiming period or 60 days, the shorter of the
      two.
      <br></br>10.12 Livepot reserves the right to cancel any bonus at its sole
      discretion.
      <br></br>11. AUTHORITY/TERMS OF SERVICE You agree to the game rules
      described on the Livepot.com website. Livepot retains authority over the
      issuing, maintenance, and closing of the Service. The decision of
      Livepot's management, concerning any use of the Service, or dispute
      resolution, is final and shall not be open to review or appeal.
      <br></br>12. PROHIBITED USES PERSONAL USE
      <br></br>12.1 The Service is intended solely for the User's personal use.
      The User is only allowed to use the Services for his/her personal
      entertainment. Users may not create multiple accounts for the purpose of
      collusion and/or abuse of service. AML & SANCTIONS COMPLIANCE
      <br></br>12.2 Livepot expressly prohibits and rejects the use of the
      Service for any form of illicit activity, including Coins laundering,
      terrorist financing or trade sanctions violations, consistent with various
      jurisdictions' laws, regulations and norms. To that end, the Service is
      not offered to individuals or entities subject to United States, European
      Union, or other global sanctions or watch lists. By using the Service, you
      represent and warrant that you are not so subject.
      <br></br>13. YOUR EQUIPMENT
      <br></br>13.1 Your computer equipment or mobile device and internet
      connection may affect the performance and/or operation of the Website.
      Livepot does not guarantee that the Website will operate without faults or
      errors or that Livepot services will be provided without interruption.
      Livepot does not accept any liability for any failures or issues that
      arise due to Your equipment, internet connection or internet or
      telecommunication service provider (including, for example, if You are
      unable to place bets or wagers or to view or receive certain information
      in relation to particular events).
      <br></br>13.2 For customers using a mobile device for the placing of
      bets/wagers, please note that Livepot will not be responsible for any
      damage to, or loss of data from the mobile device that the software is
      installed on, and will also not be responsible for any call, data or other
      charges incurred whilst using the software.
      <br></br>13.3 Due to limited display sizes on mobile devices, the mobile
      experience might differ slightly from other platforms. Differences might
      include, but are not limited to, the location of certain information on
      the platform and game names not being visible on all game pages.
      <br></br>14. FAIR USE
      <br></br>14.1 The Website and Services may only be used for recreational
      purposes by placing bets and wagers on events and/or gaming products.
      <br></br>14.2 You must not use the Website for the benefit of a third
      party or for any purpose which is illegal, defamatory, abusive or obscene,
      or which Livepot considers discriminatory, fraudulent, dishonest or
      inappropriate. Livepot may report to the authorities any activity which it
      considers to be suspicious and/or in breach of this paragraph.
      <br></br>14.3 If Livepot has a reasonable suspicion that You are involved
      in fraudulent, dishonest or criminal acts, as set out under applicable
      laws, via or in connection with the Website or Services, Livepot may seek
      criminal and contractual sanctions against You. Livepot will withhold
      purchase to any customer where any of these are suspected or where the
      purchase is suspected to be for the benefit of a third party.
      <br></br>14.4 You shall indemnify and shall be liable to pay Livepot, on
      demand, all costs, charges or losses sustained or incurred by us and our
      affiliates (including any direct, indirect or consequential losses, loss
      of profit and loss of reputation) in respect of all Claims arising
      directly or indirectly from Your fraudulent, dishonest or criminal acts
      while using the Website or Services
      <br></br>14.5 Furthermore, we reserve the right not to accept, process
      and/or honour bets/wagers where it would be forbidden, unlawful or illegal
      under applicable law or regulation to do so.
      <br></br>15. SOFTWARE AND TECHNOLOGY ISSUES
      <br></br>15.1 In order for You to use the Website and Services, You may
      need to download some software (for example, games that are made available
      via on the website). Also, certain third party product providers may
      require You to agree to additional terms and conditions governing the use
      of their products that are available on or through the Website. If You do
      not accept those third party terms and conditions, do not use the relevant
      third party software. Livepot does not accept any liability in respect of
      any third party software.
      <br></br>15.2 You are only permitted to use any and all software made
      available to You via the Website for the purpose of using the Website and
      Services and, save to the extent permitted by applicable law, for no other
      purposes whatsoever.
      <br></br>15.3 We hereby grant to You a personal, non-exclusive,
      non-transferable right to use the Website for the sole purpose of
      accessing and using the Services on the Website, in accordance with these
      Terms and Conditions. This right to use our Website and will be
      immediately terminated once Your user registration is cancelled for any
      reason, and specially, but not limited to, if You make use of that right
      with the aim of generating a parallel enterprise based in our Website or
      our products, or with the aim of making use of an automated service or
      software analysing, capturing or somehow using the information shown in
      our Website.
      <br></br>15.4 You are not permitted to: a) install or load the software
      that forms part of the Website onto a server or other networked device or
      take other steps to make the software available via any form of "bulletin
      board", online service or remote dial-in or network to any other person;
      b) sub-license, assign, rent, lease, loan, transfer or copy (except as
      expressly provided elsewhere in these Terms and Conditions) Your right to
      use the Website, or the software that forms part of the Website, or make
      or distribute copies of same; c) enter, access or attempt to enter or
      access or otherwise bypass Livepot’s security system or interfere in any
      way (including but not limited to, robots or similar devices) with the
      products or the Website or attempt to make any modifications to the
      software and/or any features or components thereof; d) copy or translate
      any user documentation provided 'online' or in electronic format. e) In
      addition, except to the minimum extent permitted by applicable law in
      relation to computer programs, You are not permitted to: (i) translate,
      reverse engineer, decompile, disassemble, modify, create derivative works
      based on, or otherwise modify the Website; or (ii) reverse engineer,
      decompile, disassemble, modify, adapt, translate, make any attempt to
      discover the source code of the software that forms part of the Website or
      to create derivative works based on the whole or on any part of the
      Website.
      <br></br>15.5 You do not own the software that forms part of the Website.
      Such software is owned and is the exclusive property of Livepot or a third
      party software provider company (any such third party provider, the
      "Software Provider"). Any software and accompanying documentation which
      have been licensed to Livepot are proprietary products of the Software
      Provider and protected throughout the world by copyright law. Your use of
      the software does not give You ownership of any intellectual property
      rights in the software.
      <br></br>15.6 The software is provided "as is" without any warranties,
      conditions, undertakings or representations, express or implied, statutory
      or otherwise. Livepot hereby excludes all implied terms, conditions and
      warranties, including any of merchantability, merchantable quality,
      satisfactory quality and fitness for any particular purpose, completeness
      or accuracy of the services or the software or infringement of applicable
      laws and regulations. Livepot does not warrant or condition that: (i) the
      software will meet Your requirements; (ii) the software will not infringe
      any third party’s intellectual property rights; (iii) the operation of the
      software will be error free or uninterrupted; (iv) any defects in the
      software will be corrected; or (v) the software or the servers are
      virus-free.
      <br></br>15.7 In the event of communications or system errors occurring in
      connection with the settlement of accounts or other features or components
      of the software, neither Livepot nor the Software Provider will have any
      liability to You or to any third party in respect of such errors. Livepot
      reserves the right in the event of such errors to remove all relevant
      products from the Website and take any other action to correct such
      errors.
      <br></br>15.8 You hereby acknowledge that how You use the software is
      outside of Livepot’s control. Accordingly, You install and/or use the
      software at Your own risk. Livepot will not have any liability to You or
      to any third party in respect of Your receipt of and/or use of the
      software.
      <br></br>15.9 The software may include confidential information which is
      secret and valuable to the Software Provider and/or Livepot. You are not
      entitled to use or disclose that confidential information other than
      strictly in accordance with these Terms and Conditions.
      <br></br>15.10 Livepot shall not be liable if for any reason the Website
      is unavailable at any time or for any period. We reserve the right to make
      changes or corrections to or to alter, suspend or discontinue any aspect
      of the Website and the content or services or products available through
      it, including Your access to it.
      <br></br>15.11 You must not misuse the Website by introducing viruses,
      trojans, worms, logic bombs or other material which is malicious or
      technologically harmful. In particular, You must not access the Website
      without authority, interfere with, damage or disrupt the Website or any
      part of it, any equipment or network on which the Website is hosted, any
      software used in connection with the provision of the Website, or any
      equipment, software or website owned or used by a third party. You must
      not attack our Website via a denial-of-service attack. We will not be
      liable for any loss or damage caused by a distributed denial-of-service
      attack, viruses or other technologically harmful material that may infect
      Your computer equipment, computer programs, data or other proprietary
      material arising due to Your use of the Website, software or to Your
      downloading of any material posted on it, or on any website linked to it.
      <br></br>16. THIRD PARTY CONTENT
      <br></br>16.1 Livepot receives feeds, commentaries and content from a
      number of suppliers. Certain third party product providers may require You
      to agree to additional terms and conditions governing the use of their
      feeds, commentaries and content. If You do not accept the relevant third
      party terms and conditions, You agree to not use the relevant feeds,
      commentaries or content.
      <br></br>16.2 Livepot does not accept any liability in respect of any
      third party feeds, commentaries and content.
      <br></br>16.3 Where the Website contains links to third party websites and
      resources, these links are provided for Your information only. Livepot has
      no control over the content of these sites or resources, and accepts no
      liability for them or for any loss or damage that may arise from Your use
      of them. The inclusion of a link to a third party website does not
      constitute an endorsement of that third party’s website, product or
      services, if applicable.
      <br></br>17. ERRORS Livepot will not be liable in the event You try to or
      obtain an advantage from any errors in respect of bets or wagers on the
      Website if You were deliberately acting in bad-faith, including where: (i)
      there is an obvious error in the relevant odds, spreads, handicap, totals,
      cash-out; (ii) Livepot continues to accept bets or wagers on closed or
      suspended markets; (iii) Livepot incorrectly calculates or pays a
      settlement amount, including where a bet is Cashed Out for the full
      settlement amount, or a bet is made void incorrectly, where ‘Void if User
      does not start’ was selected at bet placement; or (iv) any error occurs in
      a random number generator or pay tables included, incorporated or used in
      any game or product.
      <br></br>18. BREACH
      <br></br>18.1 Without prejudice to any other rights, if a User breaches in
      whole or in part any provision contained herein, Livepot reserves the
      right to take such action as it sees fit, including terminating this
      Agreement or any other agreement in place with the User and/or taking
      legal action against such User.
      <br></br>18.2 You agree to fully indemnify, defend and hold harmless
      Livepot and its shareholders, directors, agents and employees from and
      against all claims, demands, liabilities, damages, losses, costs and
      expenses, including legal fees and any other charges whatsoever, howsoever
      caused, that may arise as a result of: a) your breach of this Agreement,
      in whole or in part; b) violation by you of any law or any third party
      rights; and c) use by you of the Service.
      <br></br>19. LIMITATIONS AND LIABILITY
      <br></br>19.1 Under no circumstances, including negligence, shall Livepot
      be liable for any special, incidental, direct, indirect or consequential
      damages whatsoever (including, without limitation, damages for loss of
      business profits, business interruption, loss of business information, or
      any other pecuniary loss) arising out of the use (or misuse) of the
      Service even if Livepot had prior knowledge of the possibility of such
      damages.
      <br></br>19.2 Nothing in this Agreement shall exclude or limit Livepot's
      liability for death or personal injury resulting from its negligence.
      <br></br>20. INTELLECTUAL PROPERTY
      <br></br>20.1 Livepot and its licensors are the sole holders of all rights
      in and to the Service and code, structure and organisation, including
      copyright, trade secrets, intellectual property and other rights. You may
      not, within the limits prescribed by applicable laws: (a) copy,
      distribute, publish, reverse engineer, decompile, disassemble, modify, or
      translate the website; or (b) use the Service in a manner prohibited by
      applicable laws or regulations (each of the above is an "Unauthorised
      Use"). Livepot reserves any and all rights implied or otherwise, which are
      not expressly granted to the User hereunder and retain all rights, title
      and interest in and to the Service. You agree that you will be solely
      liable for any damage, costs or expenses arising out of or in connection
      with the commission by you of any Unauthorized Use. You shall notify
      Livepot immediately upon becoming aware of the commission by any person of
      any Unauthorised Use and shall provide Livepot with reasonable assistance
      with any investigations it conducts in light of the information provided
      by you in this respect. 20.2 The term "Livepot", its domain names and any
      other trade marks, or service marks used by Livepot as part of the Service
      (the "Trade Marks"), are solely owned by Livepot. In addition, all content
      on the website, including, but not limited to, the images, pictures,
      graphics, photographs, animations, videos, music, audio and text (the
      "Site Content") belongs to Livepot and is protected by copyright and/or
      other intellectual property or other rights. You hereby acknowledge that
      by using the Service, you obtain no rights in the Site Content and/or the
      Trade Marks, or any part thereof. Under no circumstances may you use the
      Site Content and/or the Trade Marks without Livepot's prior written
      consent. Additionally, you agree not to do anything that will harm or
      potentially harm the rights, including the intellectual property rights of
      Livepot.
      <br></br>21. DISPUTES If a User wishes to make a complaint, please contact
      Livepot's customer service team. Should any dispute not be resolved to
      your satisfaction you may pursue remedies in the governing law
      jurisdiction set forth below.
      <br></br>22. AMENDMENT Livepot reserves the right to update or modify this
      Agreement or any part thereof at any time or otherwise change the Service
      without notice and you will be bound by such amended Agreement upon
      posting. Therefore, we encourage you check the terms and conditions
      contained in the version of the Agreement in force at such time. Your
      continued use of the Service shall be deemed to attest to your agreement
      to any amendments to the Agreement.
      <br></br>23. GOVERNING LAW The Agreement and any matters relating hereto
      shall be governed by, and construed in accordance with, the laws of Lao
      PDR. You irrevocably agree that, subject as provided below, the courts of
      Lao PDR shall have exclusive jurisdiction in relation to any claim,
      dispute or difference concerning the Agreement and any matter arising
      therefrom and irrevocably waive any right that it may have to object to an
      action being brought in those courts, or to claim that the action has been
      brought in an inconvenient forum, or that those courts do not have
      jurisdiction. Nothing in this clause shall limit the right of Livepot to
      take proceedings against you in any other court of competent jurisdiction,
      nor shall the taking of proceedings in any one or more jurisdictions
      preclude the taking of proceedings in any other jurisdictions, whether
      concurrently or not, to the extent permitted by the law of such other
      jurisdiction.
      <br></br>24. SEVERABILITY If a provision of this Agreement is or becomes
      illegal, invalid or unenforceable in any jurisdiction, that shall not
      affect the validity or enforceability in that jurisdiction of any other
      provision hereof or the validity or enforceability in other jurisdictions
      of that or any other provision hereof.
      <br></br>25. ASSIGNMENT Livepot reserves the right to assign this
      agreement, in whole or in part, at any time without notice. The User may
      not assign any of his/her rights or obligations under this Agreement.
      <br></br>26. MISCELLANEOUS
      <br></br>26.1 No waiver by Livepot of any breach of any provision of this
      Agreement (including the failure of Livepot to require strict and literal
      performance of or compliance with any provision of this Agreement) shall
      in any way be construed as a waiver of any subsequent breach of such
      provision or of any breach of any other provision of this Agreement.
      <br></br>26.2 Nothing in this Agreement shall create or confer any rights
      or other benefits in favour of any third parties not party to this
      Agreement.
      <br></br>26.3 Nothing in this Agreement shall create or be deemed to
      create a partnership, agency, trust arrangement, fiduciary relationship or
      joint venture between you and Livepot.
      <br></br>26.4 Livepot may assign, transfer, charge, sub-license, or deal
      in any other manner with this Agreement, or sub-contract any of its rights
      and obligations under this Agreement, to any other party.
      <br></br>26.5 This Agreement constitutes the entire understanding and
      agreement between you and Livepot regarding the Service and supersedes any
      prior agreement, understanding, or arrangement between you and Livepot.
      <br></br>27. COMPLAINTS
      <br></br>27.1 If you have a complaint to make regarding our services, you
      may contact our customer support. We will endeavour to resolve the matter
      promptly.
    </div>
  );
}
