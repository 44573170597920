
export const routes = {
    home: "/",
    project: "/project",
    token: "/token",
    // tokenomics: "/tokenomics",
    platform: "/platform",
    tAndCVirtual: "/t&c-virtual",
    privacyPolicy: "/privacy-policy",
    tAndC: "/t&c",

    error404: "*"
}