import React from "react";
import Card from "./Card";
import "./FirstProject.scss";

import img1 from "./Assets/singlePlayer.png";
import img2 from "./Assets/stack.png";
import img3 from "./Assets/joistick.png";

import SliderGrab from "../../../Components/SliderGrab/SliderGrab";
import Tag from "../../../Components/Tags/Tag";
import Button from "../../../Components/Button/Button";
import { TbCirclePlus } from "react-icons/tb";
import plusIcon from "./Assets/plus-circle 1.png";
import ButtonOutline from "../../../Components/ButtonOutline/ButtonOutline";
const FirstProject = () => {
  return (
    <section
      id="first-project-section"
      className="first-project section-padding white-color mb-lg-5 mb-0"
    >
      <div className="large-container pe-0 ps-1500-0">
        <SliderGrab>
          <div className="d-flex justify-content-center align-items-center container-cards  mx-auto">
            <Card
              cardTitle="Single Player"
              extraClassImg="negative-margin"
              extraClass={"single-player-card"}
              cardParagraph={
                <>
                  Enjoy games that don’t require more than one player. Focus on
                  your individual decision-making, strategy, and fun without the
                  pressure of having team-mates or opponents.
                  <Button
                    title="Learn More"
                    height={32}
                    heightResponsive={32}
                    heightResponsive580={32}
                    minWidth580={130}
                    minWidth991={130}
                    minWidth={130}
                    icon={plusIcon}
                    fontSize={14}
                    width={135}
                    color="#fff"
                    outlineColor="transparent"
                    bgColor="linear-gradient(90deg, #8F2EB7 0%,  #B97338 100%) 0% 0% no-repeat padding-box "
                    hoverColor="#fff"
                    bgHoverColor="#08FF9B"
                    customClass="z-2 radius64 font-ri-m font-size-14 me-3 mt-3"
                    callback={(e) => {
                      window.open("https://play.livepot.com", "_blank");
                    }}
                  />
                </>
              }
              cardImg={img1}
            />
            <Card
              extraClass="background-lipo-gradient"
              extraClassImg="negative-margin"
              cardTitle="Multiplayer"
              cardParagraph={
                <>
                  <span className="text-white ">
                    Play and compete with other people. Enjoy the social
                    interaction, competition, and excitement of facing off
                    against other players.
                  </span>
                  <br></br>
                  <br></br>
                  <ButtonOutline
                    title="Learn More"
                    height={32}
                    heightResponsive={32}
                    heightResponsive580={32}
                    minWidth580={130}
                    minWidth991={130}
                    minWidth={130}
                    icon={plusIcon}
                    fontSize={14}
                    width={135}
                    color="#fff"
                    outlineColor="transparent"
                    bgColor="trasnparent"
                    hoverColor="#fff"
                    bgHoverColor="#E169A0"
                    customClass="z-2 radius64 font-ri-m font-size-14 me-3 mt-3"
                    callback={(e) => window.open("https://play.livepot.com")}
                  />
                </>
              }
              cardImg={img2}
            />
            <Card
              cardTitle="Tournaments"
              extraClassImg="negative-margin"
              cardParagraph={
                <>
                  Face off against other players and teams for various prizes.
                  Outlast or outperform other competitors in a series of rounds
                  or through accumulating the highest number of prizes.
                  <Button
                    title="Learn More"
                    height={32}
                    heightResponsive={32}
                    heightResponsive580={32}
                    minWidth580={130}
                    minWidth991={130}
                    minWidth={130}
                    icon={plusIcon}
                    fontSize={14}
                    width={135}
                    color="#fff"
                    outlineColor="transparent"
                    bgColor="linear-gradient(90deg, #8F2EB7 0%,  #B97338 100%) 0% 0% no-repeat padding-box "
                    hoverColor="#fff"
                    bgHoverColor="#08FF9B"
                    customClass="z-2 radius64 font-ri-m font-size-14 me-3 mt-3"
                    callback={(e) => {
                      window.open("https://play.livepot.com", "_blank");
                    }}
                  />
                </>
              }
              cardImg={img1}
            />
            <Card
              extraClassImg="negative-margin"
              cardTitle="Spectate Stream"
              cardParagraph={
                <>
                  Watch others play games in real-time. Observe strategies,
                  learn tips, and follow major tournaments or high-stakes games.
                  <br></br>
                  <br></br>
                  <Button
                    title="Learn More"
                    height={32}
                    heightResponsive={32}
                    heightResponsive580={32}
                    minWidth580={130}
                    minWidth991={130}
                    minWidth={130}
                    icon={plusIcon}
                    fontSize={14}
                    width={135}
                    color="#fff"
                    outlineColor="transparent"
                    bgColor="linear-gradient(90deg, #8F2EB7 0%,  #B97338 100%) 0% 0% no-repeat padding-box "
                    hoverColor="#fff"
                    bgHoverColor="#08FF9B"
                    customClass="z-2 radius64 font-ri-m font-size-14 me-3 mt-3"
                    callback={(e) => {
                      window.open("https://play.livepot.com", "_blank");
                    }}
                  />
                </>
              }
              cardImg={img3}
            />
          </div>
        </SliderGrab>
      </div>
    </section>
  );
};

export default FirstProject;
