import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Homepage from "./Views/Homepage/Homepage";
import ErrorPage from "./Views/ErrorPage/ErrorPage";
import Navbar from "./Components/Navbar/Navbar";
import PageTracking from "./Utils/pageTracking";
import Helmet from "react-helmet";
import TC from "./Views/T&C/T&C";
import PrivacyPolicy from "./Views/PrivacyPolicy/PrivacyPolicy";
import TCVirtual from "./Views/T&CVirtual/T&CVirtual";


const App = () => {
  PageTracking();

  // const { pathname } = useLocation();

  // // if (pathname === "/") {
  // //   document.body.classList.add("gaming-page-navbar");
  // // }

  return (
    <div className="App position-relative">
      {/* global components */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        // autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={2}
        pauseOnFocusLoss
        draggable
        closeButton={true}
      />

      <Navbar />

      <Helmet>
        <title>LivePot | Next Web3 | Streaming | Gaming Platform</title>
        <meta
          name="description"
          content="LivePot is a Gaming platform based on the revolutionary Crypto Betting concept and developed on cutting-edge Web3 technology."
        />
      </Helmet>

      {/* routes */}
      <Routes>
        {/* <Route path="/home" element={<Homepage />} />
        <Route path="/token" element={<TokenPage />} />
        <Route path="/platform" element={<PlatformPage />} />
        <Route path="/project" element={<ProjectPage />} /> */}
                <Route path="/t&c" element={<TC />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="t&c-virtual" element={<TCVirtual />} />

        <Route path="/" element={<Homepage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
};

export default App;
