import React from "react";
import Footer from "../../Components/Footer/Footer";

export default function PrivacyPolicy() {
  return (
    <div className="container white-color pt-5">
      <h1 className="text-center font-iner-bold font-size-40 mt-5">
        {" "}
        Privacy Policy
      </h1>
      <br></br>
      <br></br>This is the privacy policy of LIMITLESS PAY CO., LTD (owner and
      operator of Livepot.com.) This Privacy Policy sets out how Livepot.com
      will manage your personal information which we collect as a result of your
      membership of Livepot, your use of our websites located at
      https://Livepot.com/ and any pages or websites under the 'Livepot' brand
      applications that are owned and/or operated by us or whenever we otherwise
      deal with you. We are committed to ensuring that the personal information
      we collect about you is protected and is used, stored and disclosed in
      accordance this Privacy Policy.
      <br></br>1. Website Use In using the website, you agree to be bound by the
      terms of this Privacy Policy. Livepot.com may review the Privacy Policy
      periodically and reserves the right to change the Privacy Policy at any
      time at its discretion by posting such changes on the website. Changes
      will be effective immediately when posted on the website. Your continued
      use of the website following posting will constitute your acceptance of
      those changes. This Privacy Policy will not apply to websites which are
      linked to the website and over which Livepot.com has no control.
      <br></br>2. Personal Information
      <br></br>The kinds of Personal Information that Livepot.com may process
      about you is:
      <br></br>• Name
      <br></br>• Email Address
      <br></br>• Personally Submitted Preferences
      <br></br>• Date of Birth
      <br></br>• Country of Citizenship
      <br></br>• Physical Address
      <br></br>• Identification Number
      <br></br>• Government Issued Identification
      <br></br>• Location Data
      <br></br>• Device Information
      <br></br>• IP Address
      <br></br>• Data related to the use of our Services and products as
      deposits, withdraws, wagers, VIP status, Affiliate ID, among others
      <br></br>• Data related to the enforcement of our Terms of Service and
      Policies. For further details, please refer to the relevant documents,
      which can be accessed here:
      <br></br>o Terms of Service
      <br></br>The Company collects, or may collect, such information through
      methods including:
      <br></br>• Data input during account registration processes
      <br></br>• Communications with Customers
      <br></br>• Data provided or created when you use our Services and products
      <br></br>• From third-party data providers, in accordance with the terms
      set forth in this Privacy Policy, in our Terms of Service, or in our
      policies
      <br></br>3. Data Processing Purposes
      <br></br>Livepot.com will process your Personal Information only by lawful
      and fair means and not in an intrusive way to operate its business as a
      licensed online wagering bookmaker for the following purposes:
      <br></br>• For know-your-customer (KYC) identity verification.
      <br></br>• If you wish to subscribe to Livepot.com's marketing
      communications.
      <br></br>• To process your application to become a Member of Livepot.com
      or any managed subdomains.
      <br></br>• To provide and improve services to you as a Member.
      <br></br>• To identify you as a Member and authenticate your identity for
      security purposes and to comply with our legal obligations.
      <br></br>• To maintain your Member account.
      <br></br>• To upgrade and enhance your experience within the website or
      over the telephone, or to tailor or develop information, services or
      products to suit your needs which may include market research and
      conducting promotions.
      <br></br>• To tell you about our products or services that we think may be
      of interest to you by communicating with you via email, SMS or telephone.
      <br></br>• To create aggregate data about Members through demographic
      profiling, statistical analysis of the database to provide to potential
      and existing stakeholders, and to allow for more efficient operation of
      Livepot.com’s business.
      <br></br>• To respond to your questions, comments, or requests.
      <br></br>• To determine Members' liability to pay goods and services tax
      and other taxes where applicable.
      <br></br>• To comply with Livepot.com’s contractual, legal, and statutory
      obligations.
      <br></br>• For taking appropriate action if Livepot.com has reason to
      suspect that unlawful activity or misconduct of a serious nature has been,
      is being or may be engaged in that relates to our functions and
      activities.
      <br></br>• To establish, exercise or defend any legal claims.
      <br></br>• To enforce our Terms of Service and Policies.
      <br></br>• To provide you with benefits related to our bonus program, as
      well as other campaigns and benefits offered by Livepot.com or any managed
      subdomains.
      <br></br>• To manage job applications.
      <br></br>If you do not provide Livepot.com with the Personal Information
      we request, we may be unable to process your application to become a
      Member, provide you with our services or respond to your enquiry or
      complaint.
      <br></br>By using Livepot.com, becoming a Member or otherwise choosing to
      provide Livepot.com with Personal Information you acknowledge that
      Livepot.com may collect, use, process and potentially disclose your
      Personal Information for the above purposes and on the bases described in
      this Privacy Policy.
      <br></br>4. Direct Marketing and Opting Out From time to time we may use
      your Personal Information to inform you about our products or services or
      about promotional activities which Livepot.com believes may be of interest
      or of benefit to you. We may do this via email, SMS, telephone or mail. If
      you no longer wish to receive marketing or promotional material from
      Livepot.com at all or in any particular form, you may contact us at any
      time by email our support with your request with which we will comply as
      soon as is practical. From time to time we may contact you in relation to
      the management and administration of your Livepot.com account. These
      communications can be via any of the modes of contact recorded when
      registering as a Member. Such communication does not affect your opt-in or
      opt-out status for direct marketing communications.
      <br></br>5. Management and Sharing of your Personal Information
      Livepot.com will take all reasonable steps to ensure that the Personal
      Information which it collects, uses or discloses is correct and is stored
      in a secure environment which is accessed only by authorised persons. We
      store the personal information we receive as described in this Privacy
      Policy for as long as you use our Services or as necessary to fulfil the
      purpose(s) for which it was collected, provide our Services, resolve
      disputes, establish legal defences, conduct audits, pursue legitimate
      business purposes, enforce our agreements, and comply with applicable
      laws. Livepot.com can share or disclose Personal Information in order to
      provide our Services, enforce our Terms of Service and policies, protect
      or defend our rights and interests, particularly in the following cases:
      <br></br>• Within our Group: Any reference to the "Group" in this Privacy
      Policy includes LIMITLESS PAY CO., LTD (the owner and operator of
      Livepot.) as well as our websites located at https://Livepot.com and any
      pages or websites under the "Livepot" brand, including any mobile phone or
      tablet applications owned and/or operated by us or whenever we otherwise
      interact with you.
      <br></br>• To our service providers and third-party partners: In some
      cases, we may use third party service providers to provide you with our
      Services or products. In this case, we may need to share or disclose
      Personal Information. In each case, Livepot.com will take reasonable steps
      to ensure that your Personal Information is protected and processed as set
      out in this Policy. Whenever possible, we will choose to contract with a
      data processor to handle your Personal Information.
      <br></br>• For legal or regulatory reasons: In certain circumstances, we
      may be required to disclose your Personal Information in response to
      lawful requests by public authorities.
      <br></br>• In the event of a business sale or restructuring: If the
      ownership of all or part of our business changes or if we undergo a
      reorganization or restructuring, we may transfer your Personal Information
      to the new owner or successor company so that we can continue to provide
      the Services requested. Whenever we share Personal Information and
      whatever the circumstances, we will always do so lawfully and with due
      regard to your privacy. Livepot.com will destroy or permanently
      de-identify the Personal Information we hold when it is no longer required
      for any purpose permitted under this Privacy Policy or our legal or
      operational obligations. Livepot.com will destroy or permanently
      de-identify the Personal Information we hold when it is no longer required
      for any purpose permitted under our legal or operational obligations.
      <br></br>6. Delete Personal Data You can request to have your personal
      data deleted if Livepot.com no longer have a legal reason to continue to
      process or store it. Please note that this right is not guaranteed - in
      the sense that Livepot.com do not have the ability to comply with your
      request if it is subject to a legal obligation to store your data. You can
      request the deletion of your personal data by sending an email to our
      support function.
      <br></br>7. Legal Basis for Processing For purposes of the processing,
      Livepot.com’s processing of your personal information is supported by the
      following lawful bases: <br></br>• Performance of a Contract: Livepot.com
      needs to process your personal information to perform our contract with
      you or our Customer. <br></br>• Legitimate Interest: Livepot.com processes
      your personal information to further our legitimate interests but only
      where our interests are not overridden by your interests or fundamental
      rights and freedoms. <br></br> • Consent: In some cases, Livepot.com also
      relies on your consent to process your personal information. <br></br>•
      Compliance with Legal Obligations: Livepot.com may process your personal
      information to comply with our legal obligations.
    </div>
  );
}
