import React from "react";
import Footer from "../../Components/Footer/Footer";

export default function TCVirtual() {
  return (
    <div className="container white-color pt-5">
      <h1 className="text-center font-iner-bold font-size-40 mt-5">
        {" "}
        Virtual Items Terms and Conditions
      </h1>
      <br></br>
      These terms and conditions apply for the purchase and use virtual items on
      Livepot.com . More information on the Terms and Conditions for Livepot.com
      are on the dedicated page. On this page please read these terms and
      conditions ("Virtual Items Terms") carefully before purchasing any virtual
      items, benefits, upgrades, unlockables, cosmetics, skins, gameplay
      features or coins, tokens or other in-game currency ("Coins"), in each
      case, available through or otherwise in relation to our Services (together
      "Virtual Items"). By directly or indirectly purchasing any Virtual Items,
      you agree to be bound by these Virtual Items Terms and the Terms and
      Conditions of Livepot.com . These Virtual Items Terms are in addition to
      the Terms and Conditions of Livepot.com and if there is a conflict between
      the Virtual Items Terms and the Terms and Conditions of Livepot.com, the
      Virtual Items Terms shall apply.
      <br></br>All Coins used on the platform constitute virtual in-game
      currency ("play money") without any real-world monetary or exchange value.
      Coins are not backed or guaranteed by any financial institution or
      governmental body and cannot be redeemed or exchanged for fiat currency,
      cryptocurrencies, or any tangible goods or services. Coins are solely
      intended for entertainment purposes within the Livepot.com ecosystem.
      <br></br>
      1. Scope of Virtual Items Term
      <br></br>1.1. These Virtual Items Terms cover your purchase and use of
      Virtual Items. Please be aware that a number of our Services have more
      than one type of in-game currency. If this is the case, there will be
      different items which can be purchased with each type of in-game currency,
      and each may be acquired in different ways.
      <br></br> 1.2. Coins and items can only be used in line with these Virtual
      Items Terms and within the game account in which they are purchased or
      otherwise acquired. You acknowledge that certain additional in-game
      functionality may only be accessible in exchange for Coins.
      <br></br>1.3. These Virtual Items Terms also apply if you win or are given
      any Virtual Items because you have participated in a gameplay feature,
      competition, survey or marketing campaign.
      <br></br>1.4. Please note some of our Services may include “loot boxes” as
      part of the Virtual Items offered in the game. Loot boxes can take various
      forms (such as golf bags, treasure chests or player packs) are usually
      considered to be a form of randomised in-game content you can open or
      redeem through various means, including for example for in-game currency,
      coins, or through gameplay. Loot boxes are subject to these Virtual Items
      Terms.
      <br></br>2. Purchasing Virtual Items on the Website
      <br></br> 2.1. Some of our games let you purchase Virtual Items. You can
      recognise these games because they have an option to purchase Virtual
      Items in-game shop. Games may allow you to buy different amounts of
      Virtual Items and may have temporary offers or sales for Virtual Item
      purchases.
      <br></br>2.2. We permit third parties to give or sell Coins and other
      Virtual Items outside of our games. Accordingly, as a condition to playing
      our games, you agree not to have any claim from such transaction against
      Livepot and represent that any such transaction is outside of these
      Virtual Item Terms.
      <br></br>3. Purchase conditions for purchasing Virtual Items
      <br></br>3.1. You can buy Virtual Items from us, from any platforms and
      other Users. If you want to buy any Virtual Items, please follow the steps
      set out in the game which you have downloaded. If you experience any
      difficulties when purchasing Virtual Items, you can contact us.
      <br></br>3.2. Please note the following:
      <br></br>• You may be presented with multiple Virtual Items bundles, with
      different virtual currency options if applicable, and with different
      prices and amounts of Virtual Items to choose from. Please select the
      bundle that you wish to purchase, review this selection and, if you are
      happy, please proceed to check out. If you are purchasing Virtual Items
      through the Website, you will automatically be redirected to our
      third-party purchase provider website. If you are purchasing Virtual Items
      through an app, your purchase will be processed through the applicable app
      store and additional app store terms and conditions may apply.
      <br></br>• The price of Virtual Items is as stated at the time you place
      your order, except in the case of obvious error. We try and ensure that
      all prices stated are accurate but errors may occur. The price includes
      value added tax (where applicable).
      <br></br>• We are entitled to change the purchase price for Virtual Items
      at any time, before you purchase the Virtual Items. At the time of
      purchase of Virtual Items, you may be offered additional Virtual Items
      free of charge. These Virtual Items Terms cover such additional Virtual
      Items offered free of charge. Subject to applicable law and regulation,
      the price of Virtual Items may vary between users depending on a number of
      factors including differing exchange rates, territories and legal
      limitations.
      <br></br>• If you are purchasing Virtual Items through a third-party
      purchase provider window, you will be asked to select your preferred
      purchase method. Note that such purchase methods are subject to change at
      the discretion of Livepot and the third-party purchase provider.
      <br></br>• Please check the accuracy of the order information that you
      have provided and correct any mistakes. When you are ready, please click
      to submit your order. By doing so you: (i) agree to be bound by the Terms
      and Conditions of Livepot.com and these Virtual Items Terms; (ii) agree to
      pay for the Virtual Items at the indicated price, and you (iii) confirm
      that you are permitted to use the purchase source that you have provided;
      and (iv) you authorise our designated purchase processor to charge the
      full amount to the purchase source you have identified for the
      transaction.
      <br></br>• Once you have placed your order, you will receive a
      confirmation on screen.
      <br></br>• All purchased or allocated Virtual Items will be assigned to
      the account that is linked with the purchase provider through which the
      purchase or allocation is made. It is your responsibility to ensure that
      you are logged in to the correct account when making a purchase.
      <br></br>• Livepot does not process FIAT purchases. If you have any issues
      or problems with purchase for a Virtual Item, you should therefore contact
      the third-party purchase provider or the app store directly.
      <br></br>4. Transferring Virtual Items between accounts Generally, no. The
      accounts are not always linked, but in some cases certain Virtual Items
      can be sent to other users’ wallets.
      <br></br> 5. Use of Virtual Items
      <br></br>5.1. You can spend your Coins in order to play Games and/or
      acquire Virtual Items within the game within which you purchased the
      Coins. When you use Coins to play Games and/or acquire another Virtual
      Item within a game, the relevant amount of Coins will automatically be
      deducted from your account that is connected with that game.
      <br></br>5.2. Other than Coins, the nature and duration of any Virtual
      Items will vary between and within our games. Some Virtual Items may only
      be used a limited number of times or only whilst you satisfy certain
      conditions within the relevant game.
      <br></br>5.3. We can at any time, without notifying you, vary the scope,
      variety and type of Virtual Items. Livepot has no obligation to make, or
      continue to make, any specific Virtual Item for any game. We may also
      restrict or vary the type of Virtual Items available to you based on your
      country of residence.
      <br></br>5.4. We may from time to time limit the use of Virtual Items,
      including applying limits to the number of Virtual Items allocated to your
      account at any time, and/or the number of Virtual Items usable or
      redeemable within a given time period.
      <br></br>6. Limited use of Virtual Items
      <br></br>6.1. When you buy Virtual Items, you are buying a limited
      personal revocable licence to use the Virtual Items. You agree that the
      Virtual Items do not constitute personal property and Coins are not legal
      tender or currency of any kind. All Virtual Items purchased by you are
      non-refundable. No interest is paid or earned on any Coins balance.
      <br></br>6.2. Livepot is entitled to expire, withdraw, replace, restrict
      or otherwise change any Virtual Item at any time.
      <br></br>6.3. You have certain rights in respect of the Virtual Items.
      These include that we have the right to supply the Virtual Items to you
      and that the Virtual Items will be of satisfactory quality and as
      described.
      <br></br>7. Cancelling purchases of Virtual Items
      <br></br>By purchasing Virtual Items from us, you agree that we can
      transfer them to your account as soon as we can. By agreeing to these
      terms, each time you click to confirm a purchase, you acknowledge that you
      expressly consent to the supply of the Virtual Items immediately and
      acknowledge that you will lose your right to change your mind and cancel
      such order.
      <br></br>8. What happens to my Virtual Items if your account is
      temporarily or permanently terminated? If we temporarily or permanently
      ban your account, we may remove any or all of the Virtual Items in your
      account. If this happens, you are not entitled to any reimbursement,
      refund or any other compensation, except at our sole discretion.
      <br></br>9. What is Livepot not responsible for? We are not responsible
      for the following types of loss or damage which may arise (and have no
      obligation to refund for any purchase of Virtual Items):
      <br></br>• damage or loss not caused by our breach of the Virtual Items
      Terms;
      <br></br>• unauthorised purchases made by persons who have gained access
      to your account (including, without limitation, minors or unauthorised
      third parties);
      <br></br>• loss of progress or Virtual Items on your guest account which
      you can no longer access. We advise you to create a proper account and/or
      to link your account to Facebook to minimise loss of your account data;
      <br></br>• loss or liability incurred by you as a result of accidentally
      entering a match or purchasing a Virtual Item through misclick or other
      mistake;
      <br></br>• loss of progress or Virtual Items as a result of our decision
      to terminate a game or remove certain features or supporting services from
      a game;
      <br></br>• loss or damage which is caused by us when such use does not
      comply with the Virtual Items Terms but which we and you could not
      anticipate nor expect to happen when you started using the Virtual Items;
      <br></br>• any loss which is indirect or a side effect of the main loss or
      damage and which we and you could not anticipate nor expect to happen when
      you started using the Virtual Items, for instance if you lose revenue or
      salary, profit, opportunity or reputation; and
      <br></br>• any loss or damage if the Virtual Items are not provided to you
      are interrupted or suspended or if we do not comply with the Virtual Items
      Terms because of events beyond our control, such as an act of God,
      accident, fire, lockout, strike or other official or unofficial labour
      dispute, civil commotion or other act or event beyond our reasonable
      control.
      <br></br> 10. Use of Coins on Livepot Coins as play money Disclaimer
      <br></br>10.1. Coins purchased, acquired, or otherwise obtained through to
      use the Services on Livepot are strictly virtual and constitute play money
      without any inherent monetary or exchange value. Coins do not represent
      real currency, legal tender, or assets of any kind, nor do they hold any
      investment value. To this end, participation on the platform and the
      acquisition of Coins do not represent an investment, financial
      opportunity, or financial instrument of any kind. Livepot.com provides no
      guarantee whatsoever of winnings, returns, or financial gain from using
      the services.
      <br></br>10.2. Any exchanges, transactions, or transfers involving Coins
      occur solely between Users and solely to be used for the Services and no
      other functions or other use are promised. All entries (deposits or
      purchases) and exits (withdrawals) of Coins occur exclusively within the
      internal Livepot ecosystem and personal user wallets (such as the LIPO BNB
      wallet). Coins cannot be withdrawn outside of this ecosystem or converted
      into fiat or cryptocurrency. Livepot does not guarantee, endorse, or
      facilitate any value or redemption outside the virtual environment
      provided by our Services. Users acknowledge and agree that Livepot does
      not promise nor guarantee that Coins can be exchanged, refunded, resold,
      redeemed, or otherwise transferred for real Coins, goods, services,
      cryptocurrencies, or any tangible or intangible items outside the Service.
      <br></br>10.3. Users expressly acknowledge that the purchase or use of
      Coins does not constitute an investment, speculation, or financial
      opportunity of any kind. Coins do not accrue interest, dividends, or
      returns, and their value is strictly limited to their use within the
      Service for entertainment purposes only.
      <br></br>10.4. By purchasing or acquiring Coins, Users accept that they
      hold no claim against Livepot regarding the monetary value or utility of
      the Coins. Users agree not to treat the purchase or accumulation of Coins
      as a financial instrument, investment, or security.
      <br></br>10.5. Livepot expressly disclaims any liability related to
      perceived, implied, or actual financial value or losses resulting from
      transactions involving Coins. Users agree that Livepot has no obligation
      to repurchase or otherwise compensate Users for unused Coins or any
      perceived depreciation in value.
      <br></br>10.6. All disclaimers relating to cryptocurrencies, digital
      assets, or real-world currencies contained in these Terms and Conditions,
      or elsewhere on Livepot.com, apply explicitly and fully to Coins. Users
      must carefully review and acknowledge such disclaimers before purchasing,
      acquiring, or using Coins.
    </div>
  );
}
