import React, { useCallback, useEffect, useRef, useState } from "react";
import { Accordion, Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { navbarRoutes } from "../../Globals/navbarRoutes.js";
import { AiOutlinePlus as Plus } from "react-icons/ai";
import { ReactComponent as Logo } from "../..//Assets//Global//SVG//logo.svg";
import logo2 from "../../Assets//Global/lp-logo.png";
import "./Navbar.scss";
import { toast } from "react-toastify";
import Button from "../Button/Button.jsx";
import HoursCountdown from "../HoursCountdown/HoursCountdown.jsx";

const Navbar = (props) => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  const [visibleAfterCountdown, setVisibleAfterCountdown] = useState(true);
  const getRemainingHours = (visible) => {
    setVisibleAfterCountdown(false);
  };

  const ref = useRef(null);
  const closeRef = useRef(null);
  const navigate = useNavigate();

  const handleNavigation = (e, route) => {
    // e.stopPropagation();
    if (!route?.submenu) {
      setExpanded(false);
      navigate(route.route[0]);
    } else {
      navigate(route.route[0]);
    }
  };

  const toggleNavbar = () => {
    setExpanded((prev) => !prev);
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        closeRef.current &&
        !closeRef.current.contains(event.target)
      ) {
        if (expanded) {
          setExpanded(false);
        }
      }
    },
    [ref, closeRef, expanded]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, [ref, expanded, handleClickOutside]);

  return (
    <nav
      className={`w-100 mt-lg-3 mt-0 
      ${
        document.body.classList.contains("gaming-page-navbar") ? "mt-lg-5" : ""
      } 
        `}
    >
      <div className="container-main w-auto ">
        <div className="">
          <ul className="links-container mb-0 w-100 d-none d-lg-flex align-items-center justify-content-between gap-3 px-5">
            <Link to="/">
              <Logo />
            </Link>

            <Button
              title="Play Now"
              height={40}
              heightResponsive={48}
              width={148}
              heightResponsive580={40}
              color="#fff"
              outlineColor="transparent"
              bgColor="linear-gradient(90deg, #8F2EB7 0%,  #B97338 100%) 0% 0% no-repeat padding-box "
              hoverColor="#fff"
              bgHoverColor="#08FF9B"
              customClass="z-2 radius64 font-ri-m font-size-20  "
              callback={(e) => {
                //open new link in new tab
                window.open("https://play.livepot.com");
              }}
            />

            {document.body.classList.contains("gaming-page-navbar") ? (
              // <div className='hours-countdown-container'>
              //   <HoursCountdown date={new Date('2024-07-04T17:00:00')} remainingHours={getRemainingHours} />
              // </div>
              <div className="stay-tuned-wrapper  font-size-16 font-iner-regular">
                STAY TUNED
              </div>
            ) : null}
          </ul>
        </div>
        <div className="buy-container d-flex align-items-center justify-content-between w-100 px-3">
          {/* <button className="buy-button d-flex align-items-center justify-content-center d-none d-lg-flex">
          <ShopCart />
        </button> */}
          <div className="d-lg-none d-block">
            {" "}
            <Link to="/">
              <img src={logo2} alt="" />
            </Link>
          </div>
          <div
            ref={closeRef}
            className={`button-menu-mobile d-flex d-lg-none ${
              expanded ? "button-menu-mobile-expanded" : ""
            }`}
            onClick={() => toggleNavbar()}
          >
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </div>
        <div
          ref={ref}
          className={`h-auto routes-container-mobile align-items-start justidy-content-center m gap-3 d-flex flex-column d-lg-none ${
            expanded ? "navbar-expanded" : "navbar-not-expanded"
          }`}
        >
          <Accordion defaultActiveKey={`0`}>
            {navbarRoutes.map((route, index) => {
              return (
                <Accordion.Item eventKey={`${index}`} key={index}>
                  <Accordion.Header className="font-pnsb">
                    <div
                      className={`navbar-route-mobile w-100 ${
                        route.submenu
                          ? route.route.includes(location.pathname)
                            ? "active-route"
                            : ""
                          : route.route[0] === location.pathname
                          ? "active-route"
                          : ""
                      }`}
                      // onClick={(e) => {
                      //   !route.submenu && handleNavigation(e, route);
                      // }}
                      onClick={(e) => handleNavigation(e, route)}
                    >
                      {route.title}
                    </div>{" "}
                    {route.submenu && <Plus className="button-transition" />}
                  </Accordion.Header>
                  {route.submenu ? (
                    <Accordion.Body>
                      {route?.submenuItems?.map((item, index2) => {
                        return (
                          <div
                            key={index2}
                            className={`navbar-route-mobile ${
                              item.route[0] === location.pathname
                                ? "active-route"
                                : ""
                            }`}
                            // onClick={(e) => handleNavigation(e, item)}
                            onClick={(e) => {
                              if (route.route[0] === "/token") {
                                navigate("/token");
                              } else {
                                toast.warning("Coming soon");
                              }
                            }}
                          >
                            {item.title}
                          </div>
                        );
                      })}
                    </Accordion.Body>
                  ) : null}
                </Accordion.Item>
              );
            })}
          </Accordion>

          <div className="d-none d-lg-block">
            <Button
              title="Play Now"
              height={48}
              heightResponsive={48}
              width={150}
              heightResponsive580={40}
              color="#fff"
              outlineColor="transparent"
              bgColor="linear-gradient(90deg, #8F2EB7 0%,  #B97338 100%) 0% 0% no-repeat padding-box "
              hoverColor="#fff"
              bgHoverColor="#08FF9B"
              customClass="z-2 radius64 font-ri-m font-size-20 me-3 "
              callback={(e) => {
                //open new link in new tab
                window.open("https://play.livepot.com");
              }}
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
